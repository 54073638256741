import styled from 'styled-components';
import { Text16Bold, Text16Regular } from '../../utils/texts/text.styles';

export const SelectMediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectMedia = styled(Text16Regular)`
  text-decoration: underline;
  cursor: pointer;
  color: #843efe;
`;

export const SelectedMediaContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
  min-height: 12rem;
`;

export const MediaCardContainer = styled.div`
  width: 13.6rem;
  height: 13.6rem;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  cursor: pointer;
`;

export const DeleteIconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  right: 0.2rem;
  top: 0.2rem;
  background-color: #49228b95;
  height: 2.2rem;
  border-radius: 5px;
  cursor: pointer;
`;

export const StarContainer = styled.div`
  width: 9rem;
  height: 3rem;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #00000080;
  border-radius: 1rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
`;

export const PriceInUsd = styled(Text16Bold)`
  width: 50%;
  margin-bottom: 3.8rem;
`;
