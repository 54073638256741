import React from 'react';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { selectCreateInvitationCodePending } from '../../../store/slices/invitationCode/slice';
import { createInvitationCode } from '../../../store/slices/invitationCode/asyncThunks';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { FormInput } from '../../../forms/form.styles';

export const createInvitationCodeFormSchema = yup
  .object({ code: yup.string().trim().required('Code is required') })
  .required();

const CreateInvitationCodeFormModal = ({ modalRef, onSuccess }) => {
  const createInvitationCodePending = useSelector(selectCreateInvitationCodePending);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: '',
    },
    delayError: 300,
    resolver: yupResolver(createInvitationCodeFormSchema),
  });

  const onSubmit = data => {
    dispatch(createInvitationCode(data))
      .unwrap()
      .then(_ => {
        reset();
        modalRef.current.hide();
        notifyInfo('Invitation code created successfully!');
        onSuccess();
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <FormModal
      ref={modalRef}
      title="Create Invitation Code"
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText="Submit"
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={createInvitationCodePending}
      shouldCloseOnOverlayClick={false}>
      <form>
        <FormInput label="Code" placeholder="Enter code" {...register('code')} error={errors.code?.message} />
      </form>
    </FormModal>
  );
};

export default CreateInvitationCodeFormModal;
