import { createAsyncThunk } from '@reduxjs/toolkit';
import promptGroupService from '../../../api/services/promptGroup';

export const fetchPromptGroups = createAsyncThunk('prompt-group/fetchPromptGroups', async ({ page, search }) => {
  return await promptGroupService.fetchPromptGroups(page, search);
});

export const editPromptGroup = createAsyncThunk('prompt-group/editPromptGroup', async requestedData => {
  const { promptGroupId, name, prompt } = requestedData;
  return await promptGroupService.editPromptGroup(promptGroupId, name, prompt);
});
