import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AddInvitationCodeButton, Header, Row, SearchInput, Title } from './InvitationCodesPage.styles';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirmationModal } from '../../components/utils/modals/ConfirmationModal/ConfirmationModal';
import debounce from 'lodash.debounce';
import { Table } from '../../components';
import {
  selectDeleteInvitationCodePending,
  selectFetchInvitationCodesInfo,
} from '../../store/slices/invitationCode/slice';
import { deleteInvitationCode, fetchInvitationCodes } from '../../store/slices/invitationCode/asyncThunks';
import CreateInvitationCodeFormModal from '../../components/modals/CreateInvitationCodeFormModal/CreateInvitationCodeFormModal';
import InvitationCodeItem from '../../components/Table/items/InvitationCodeItem/InvitationCodeItem';
import { notifyError, notifyInfo } from '../../utils/notify';

const InvitationCodesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get('page') || 1;
  const search = searchParams.get('search');
  const [searchValue, setSearchValue] = useState(() => search ?? '');
  const { data, total, pending } = useSelector(selectFetchInvitationCodesInfo);
  const deleteInvitationCodePending = useSelector(selectDeleteInvitationCodePending);
  const [openModal, closeModal, setConfirmationModalLoader] = useConfirmationModal();
  const createInvitationCodeRef = useRef();
  const dispatch = useDispatch();

  const changeHandler = useCallback(
    text => {
      searchParams.set('search', text);
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams],
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  useEffect(() => {
    if (page > 0) {
      dispatch(fetchInvitationCodes({ page, search: search ?? '' }));
    }
  }, [dispatch, page, search]);

  const setPage = page => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setConfirmationModalLoader(deleteInvitationCodePending);
  }, [deleteInvitationCodePending, setConfirmationModalLoader]);

  const tableHeader = [
    { label: 'Code' },
    { label: 'Used By' },
    { label: 'Created At' },
    { label: 'Actions', type: 'action' },
  ];

  return (
    <>
      <Header>
        <Title>Invitation Codes</Title>
        <Row>
          <SearchInput
            value={searchValue}
            onChange={event => {
              setSearchValue(event.target.value);
              debouncedChangeHandler(event.target.value);
            }}
            placeholder="Search"
            hideErrorMessage
          />
          <AddInvitationCodeButton title="Add Invitation Code" onClick={() => createInvitationCodeRef.current.show()} />
        </Row>
      </Header>
      <Table
        headerInformation={tableHeader}
        dataLoading={pending}
        totalCount={total}
        currentPage={page}
        perPage={10}
        setPage={setPage}
        emptyDataText={'No data'}
        fullHeight>
        {data.map(invitationCode => (
          <InvitationCodeItem
            key={invitationCode.id}
            code={invitationCode.code}
            createdAt={invitationCode.createdAt}
            influencerRequest={invitationCode.influencerRequest}
            onDelete={event => {
              openModal(
                'Delete Invitation Code',
                `Are you sure you want to delete "${invitationCode.code}" invitation code?`,
                'Delete',
                () => {
                  dispatch(deleteInvitationCode(invitationCode.id))
                    .unwrap()
                    .then(() => {
                      notifyInfo('Invitation code deleted successfully!');
                      closeModal();
                      if (page > 0) {
                        dispatch(fetchInvitationCodes({ page, search: search ?? '' }));
                      }
                    })
                    .catch(err => {
                      notifyError(err.message);
                    });
                },
                'Cancel',
                () => {
                  closeModal();
                },
              );
              event.stopPropagation();
            }}
          />
        ))}
      </Table>
      <CreateInvitationCodeFormModal
        modalRef={createInvitationCodeRef}
        onSuccess={() => {
          if (page > 0) {
            dispatch(fetchInvitationCodes({ page, search: search ?? '' }));
          }
        }}
      />
    </>
  );
};

export default InvitationCodesPage;
