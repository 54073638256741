import styled from 'styled-components';
import Input from '../../components/utils/inputs/Input/Input';
import { Text16Bold } from '../../components/utils/texts/text.styles';
import { MainButton } from '../../components/utils/buttons/Button/Button';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;

export const Title = styled(Text16Bold)`
  font-size: 2.8rem;
  margin-left: 1rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  margin-left: 1.2rem;
`;

export const SearchInput = styled(Input)`
  width: 100%;
  max-width: 36rem;
`;

export const AddInvitationCodeButton = styled(MainButton)`
  width: auto;
  height: 4.8rem;
  margin-left: 1.2rem;
  font-size: 1.5rem;
`;
