import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import { createInvitationCodeBuilder, deleteInvitationCodeBuilder, fetchInvitationCodesBuilder } from './builder';

const INIT_STATE = {
  fetchInvitationCodesInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  pending: {
    createInvitationCode: false,
    deleteInvitationCode: false,
  },
};

export const invitationCodeSlice = createSlice({
  name: 'invitationCode',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    fetchInvitationCodesBuilder(builder);
    createInvitationCodeBuilder(builder);
    deleteInvitationCodeBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const selectFetchInvitationCodesInfo = state => state.invitationCodeInfo.fetchInvitationCodesInfo;
export const selectCreateInvitationCodePending = state => state.invitationCodeInfo.pending.createInvitationCode;
export const selectDeleteInvitationCodePending = state => state.invitationCodeInfo.pending.deleteInvitationCode;

export default invitationCodeSlice.reducer;
