import { createAsyncThunk } from '@reduxjs/toolkit';
import withdrawalService from '../../../api/services/withdrawal';

export const fetchWithdrawals = createAsyncThunk('withdrawal/fetchWithdrawals', async ({ page, search }) => {
  return await withdrawalService.fetchWithdrawals(page, search);
});

export const createWithdrawal = createAsyncThunk('withdrawal/createWithdrawal', async ({ influencerId, amount }) => {
  return await withdrawalService.createWithdrawal(influencerId, amount);
});

export const fetchAvailableAmountsForWithdrawal = createAsyncThunk(
  'withdrawal/fetchAvailableAmountsForWithdrawal',
  async () => {
    return await withdrawalService.fetchAvailableAmountsForWithdrawal();
  },
);
