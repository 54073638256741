import React from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedInfluencer } from '../../../../../store/slices/influencer/slice';
import {
  GalleryIcon,
  GalleryItemContainer,
  GalleryItemInfoContainer,
  GalleryItemsList,
} from './InfluencerGalleriesListTab.styles';
import { Title, TitleExtra } from '../../../../../components/utils/texts/text.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { FlexMain } from '../../../../../components/utils/utils.styles';

function InfluencerGalleriesListTab() {
  const selectedInfluencer = useSelector(selectSelectedInfluencer);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const data = [
    {
      name: 'Channel Photo Gallery',
      pathSuffix: 'channel-photo',
      albumMediaProp: 'channelPhotosSize',
    },
    {
      name: 'Premium Photo Gallery',
      pathSuffix: 'premium-photo',
      albumMediaProp: 'premiumPhotosSize',
    },
    {
      name: 'Channel Video Gallery',
      pathSuffix: 'channel-video',
      albumMediaProp: 'channelVideosSize',
    },
    {
      name: 'Premium Video Gallery',
      pathSuffix: 'premium-video',
      albumMediaProp: 'premiumVideosSize',
    },
  ];

  return (
    <FlexMain>
      <GalleryItemsList>
        {data.map((galleryItem, index) => (
          <GalleryItemContainer
            key={index}
            onClick={() => {
              navigate(`${pathname.split('/list')[0]}/${galleryItem.pathSuffix}`);
            }}>
            <TitleExtra>{galleryItem.name}</TitleExtra>
            <GalleryItemInfoContainer>
              <GalleryIcon />

              <Title>
                {galleryItem.pathSuffix.includes('video')
                  ? selectedInfluencer.videosInfo[galleryItem.albumMediaProp]
                  : selectedInfluencer.photosInfo[galleryItem.albumMediaProp]}
              </Title>
            </GalleryItemInfoContainer>
          </GalleryItemContainer>
        ))}
      </GalleryItemsList>
    </FlexMain>
  );
}

export default InfluencerGalleriesListTab;
