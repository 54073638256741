import React, { useEffect } from 'react';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { VOICE_PROVIDER, VOICE_PROVIDER_TITLE } from '../../../utils/constants';
import { Form } from './HandleVoiceProvidersModal.styles';
import { FormInput, FormSelect } from '../../../forms/form.styles';
import Checkbox from '../../utils/inputs/CheckBox/CheckBox';
import { addVoiceProviderFormSchema } from '../../../forms/schemas/influencer.schema';

const HandleVoiceProvidersModal = ({ modalRef, onSetVoiceProviders, voiceProviders }) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      active: null,
      cartesia: {
        voiceId: '',
      },
      elevenLabs: {
        voiceId: '',
        voiceConfig: {
          stability: '0.35',
          similarityBoost: '0.75',
          style: '0.49',
          useSpeakerBoost: true,
        },
      },
    },
    delayError: 300,
    resolver: yupResolver(addVoiceProviderFormSchema),
  });

  const activeProvider = watch('active');

  useEffect(() => {
    if (voiceProviders) {
      setValue('active', voiceProviders.active);
      setValue('cartesia', voiceProviders.cartesia);
      setValue('elevenLabs', voiceProviders.elevenLabs);
    }
  }, [voiceProviders, setValue]);

  const onSubmit = data => {
    onSetVoiceProviders(data);
    modalRef.current.hide();
  };

  return (
    <FormModal
      ref={modalRef}
      title="Voice Providers"
      onClose={() => {
        modalRef.current.hide();
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText="Submit"
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      onAfterClose={() => {}}
      shouldCloseOnOverlayClick={false}>
      <Form>
        <Controller
          name="active"
          control={control}
          render={({ field }) => (
            <FormSelect
              placeholder="Select active voice provider"
              label="Active Voice Provider"
              options={[
                { value: VOICE_PROVIDER.CARTESIA, label: VOICE_PROVIDER_TITLE.CARTESIA },
                { value: VOICE_PROVIDER.ELEVEN_LABS, label: VOICE_PROVIDER_TITLE.ELEVEN_LABS },
              ]}
              selectedOption={field.value}
              handleChange={value => field.onChange(value)}
              error={errors.active?.message}
            />
          )}
        />
        {activeProvider?.value === VOICE_PROVIDER.CARTESIA && (
          <FormInput
            label="Voice ID"
            placeholder="Enter Voice ID"
            {...register('cartesia.voiceId')}
            error={errors.cartesia?.voiceId?.message}
          />
        )}
        {activeProvider?.value === VOICE_PROVIDER.ELEVEN_LABS && (
          <div>
            <FormInput
              label="Voice ID"
              placeholder="Enter Voice ID"
              {...register('elevenLabs.voiceId')}
              error={errors.elevenLabs?.voiceId?.message}
            />
            <FormInput
              label="Stability"
              placeholder="Enter stability"
              {...register('elevenLabs.voiceConfig.stability')}
              error={errors.elevenLabs?.voiceConfig?.stability?.message}
            />
            <FormInput
              label="Similarity Boost"
              placeholder="Enter similarity boost"
              {...register('elevenLabs.voiceConfig.similarityBoost')}
              error={errors.elevenLabs?.voiceConfig?.similarityBoost?.message}
            />
            <FormInput
              label="Style"
              placeholder="Enter style"
              {...register('elevenLabs.voiceConfig.style')}
              error={errors.elevenLabs?.voiceConfig?.style?.message}
            />
            <Controller
              name="elevenLabs.voiceConfig.useSpeakerBoost"
              control={control}
              render={({ field }) => {
                return (
                  <Checkbox
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                    error={errors.elevenLabs?.voiceConfig?.useSpeakerBoost?.message}>
                    Speaker Boost
                  </Checkbox>
                );
              }}
            />
          </div>
        )}
      </Form>
    </FormModal>
  );
};

export default HandleVoiceProvidersModal;
