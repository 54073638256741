import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/a/withdrawals';

class WithdrawalService {
  fetchWithdrawals = async (page, search) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching withdrawals.');
    }
  };

  createWithdrawal = async (influencerId, amount) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/${influencerId}`, { amount });
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while creating withdrawal.');
    }
  };

  fetchAvailableAmountsForWithdrawal = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/available`);
      throwErrorIfWrongStatus(response, 200);
      const { data } = response.data.data;
      return { data };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching available amounts for withdrawal.');
    }
  };
}

const withdrawalService = new WithdrawalService();
export default withdrawalService;
