import styled from 'styled-components';
import { ReactComponent as GallerySvg } from '../../assets/icons/image-03.svg';
import { ReactComponent as VideoGallerySvg } from '../../assets/icons/video-recorder.svg';
import { Text12ExtraBold, Text16Bold } from '../utils/texts/text.styles';

export const MediaBundleCard = styled.div`
  position: relative;
  overflow: hidden;
  width: 25rem;
  border-radius: 12px;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const MediaStatsContainer = styled.div`
  display: flex;
  gap: 2.2rem;
`;

export const GalleryIcon = styled(GallerySvg)``;

export const VideoGalleryIcon = styled(VideoGallerySvg)``;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const SaleContainer = styled.div`
  position: absolute;
  height: 2rem;
  transform: rotate(42.691deg);
  right: -2rem;
  top: 1.7rem;
  width: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c99aff;
  width: 10rem;
`;

export const Content = styled.div`
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const SaleText = styled(Text12ExtraBold)`
  line-height: 2.6rem;
`;

export const CaptionText = styled(Text16Bold)`
  line-height: 2.6rem;
  margin-right: 2.4rem;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e5e5e5;
  background: #f0f0f0;
  margin-top: 1.6rem;
  padding: 0.6rem 0;
  cursor: pointer;
  gap: 0.6rem;
`;

export const DeleteText = styled(Text16Bold)`
  color: #f54242;
`;
