import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth/slice';
import userSlice from './slices/user/slice';
import influencerSlice from './slices/influencer/slice';
import invitationCodeSlice from './slices/invitationCode/slice';
import influencerRequestSlice from './slices/influencerRequest/slice';
import withdrawalSlice from './slices/withdrawal/slice';
import promptGroupSlice from './slices/promptGroup/slice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    userInfo: userSlice,
    influencerInfo: influencerSlice,
    invitationCodeInfo: invitationCodeSlice,
    influencerRequestInfo: influencerRequestSlice,
    withdrawalInfo: withdrawalSlice,
    promptGroupInfo: promptGroupSlice,
  },
  devTools: false,
});
