import styled from 'styled-components';
import { ReactComponent as DownloadIconSvg } from '../../../../assets/icons/download.svg';
import { ReactComponent as CircleArrowIconSvg } from '../../../../assets/icons/circle_arrow_right.svg';

export const DownloadIcon = styled(DownloadIconSvg)`
  margin-right: 1rem;
  cursor: pointer;
  fill: '#9BACD8';
`;

export const CircleArrowIcon = styled(CircleArrowIconSvg)`
  margin-right: 1rem;
  cursor: pointer;
  fill: '#9BACD8';
`;
