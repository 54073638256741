import { createAsyncThunk } from '@reduxjs/toolkit';
import influencerService from '../../../api/services/influencer';
import { MEDIA_CATEGORY } from '../../../utils/constants';
import { base64ToFile } from '../../../utils/util';

export const fetchInfluencers = createAsyncThunk('influencer/fetchInfluencers', async ({ page, search }) => {
  return await influencerService.fetchInfluencers(page, search);
});

export const fetchInfluencerById = createAsyncThunk('influencer/fetchInfluencerById', async influencerId => {
  return await influencerService.fetchInfluencerById(influencerId);
});

export const createInfluencer = createAsyncThunk('influencer/createInfluencer', async requestData => {
  const {
    name,
    nickname,
    username,
    email,
    password,
    dateOfBirth,
    profilePhoto,
    businessConnection,
    paymentRatio,
    voiceResponseRatio,
    telegramChannelUrl,
    voiceId,
    influencerRequestId,
    cartesiaId,
    dickRatePrice,
    sextingPrice,
    lipSyncPrice,
    textOnBodyPrice,
    tippingPrice,
    promptGroup,
    personality,
    personalitySexting,
    timezone,
  } = requestData.basicInfo;
  const isVoiceCallEnabled = false;
  const { channelPhotos, premiumPhotos, channelVideos, premiumVideos } = requestData.albumMedia;
  const { prompt } = requestData.prompt;

  const formData = new FormData();

  // Basic influencer info
  if (profilePhoto && profilePhoto.startsWith('data:')) {
    const profilePhotoFile = base64ToFile(profilePhoto, 'profile-photo');
    formData.append('profilePhoto', profilePhotoFile, profilePhotoFile.name);
  }

  if (influencerRequestId) {
    formData.append('influencerRequestId', influencerRequestId);
  }
  formData.append('name', name);
  formData.append('nickname', nickname);
  formData.append('username', username);
  formData.append('email', email);
  if (password) {
    formData.append('password', password);
  }
  formData.append('dateOfBirth', dateOfBirth);
  formData.append('voiceId', voiceId);
  formData.append('businessConnectionId', businessConnection.value.id);
  if (promptGroup) {
    formData.append('promptGroupId', promptGroup.value);
  }
  if (personality) {
    formData.append('personality', personality);
  }
  if (personalitySexting) {
    formData.append('personalitySexting', personalitySexting);
  }
  formData.append('timezone', timezone.value);
  formData.append('isLiveStreamActive', false);
  formData.append('voiceResponseRatio', voiceResponseRatio);
  formData.append('paymentRatio', paymentRatio);
  formData.append('pricing[dickRate]', dickRatePrice);
  formData.append('pricing[sexting]', sextingPrice);
  formData.append('pricing[lipSync]', lipSyncPrice);
  formData.append('pricing[textOnBody]', textOnBodyPrice);
  formData.append('pricing[tipping]', tippingPrice);
  if (telegramChannelUrl) {
    formData.append('telegramChannelUrl', telegramChannelUrl);
  }

  formData.append('voiceProvider[cartesia][voiceId]', cartesiaId);

  // Media
  channelPhotos.forEach((photo, index) => {
    formData.append(`channelPhotoFiles[]`, photo.albumMedia.file, photo.albumMedia.file.name);
    formData.append(`channelPhotoData[${index}][caption]`, photo.caption);
    formData.append(`channelPhotoData[${index}][fileId]`, photo.fileId);
  });
  premiumPhotos.forEach((photo, index) => {
    formData.append(`premiumPhotoFiles[]`, photo.albumMedia.file, photo.albumMedia.file.name);
    formData.append(`premiumPhotoData[${index}][cost]`, photo.cost);
    formData.append(`premiumPhotoData[${index}][position]`, index + 1);
    formData.append(`premiumPhotoData[${index}][aiDescription]`, photo.aiDescription);
    formData.append(`premiumPhotoData[${index}][caption]`, photo.caption);
    formData.append(`premiumPhotoData[${index}][fileId]`, photo.fileId);
    formData.append(`premiumPhotoData[${index}][tag]`, photo.tag.value);
  });

  channelVideos.forEach((video, index) => {
    formData.append(`channelVideoFiles[]`, video.albumMedia.file, video.albumMedia.file.name);
    formData.append(`channelVideoData[${index}][caption]`, video.caption);
    formData.append(`channelVideoData[${index}][fileId]`, video.fileId);
  });
  premiumVideos.forEach((video, index) => {
    formData.append(`premiumVideoFiles[]`, video.albumMedia.file, video.albumMedia.file.name);
    formData.append(`premiumVideoData[${index}][cost]`, video.cost);
    formData.append(`premiumVideoData[${index}][position]`, index + 1);
    formData.append(`premiumVideoData[${index}][aiDescription]`, video.aiDescription);
    formData.append(`premiumVideoData[${index}][caption]`, video.caption);
    formData.append(`premiumVideoData[${index}][fileId]`, video.fileId);
    formData.append(`premiumVideoData[${index}][tag]`, video.tag.value);
  });

  // Main - male
  formData.append(`prompt[male][mainMessage][base][template]`, prompt.male.mainMessage.base.template);
  formData.append(`prompt[male][mainMessage][sexting][template]`, prompt.male.mainMessage.sexting.template);

  if (isVoiceCallEnabled) {
    formData.append(`prompt[male][voiceCall][template]`, prompt.male.voiceCall.template);
    formData.append(`prompt[female][voiceCall][template]`, prompt.male.voiceCall.template);
  }

  // Main - female
  formData.append(`prompt[female][mainMessage][base][template]`, prompt.male.mainMessage.base.template);
  formData.append(`prompt[female][mainMessage][sexting][template]`, prompt.male.mainMessage.sexting.template);

  return await influencerService.createInfluencer(formData);
});

export const editInfluencer = createAsyncThunk('influencer/editInfluencer', async (requestData, { getState }) => {
  const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
  const {
    profilePhoto,
    name,
    nickname,
    email,
    dateOfBirth,
    paymentRatio,
    telegramChannelUrl,
    cartesiaId,
    voiceResponseRatio,
    dickRatePrice,
    sextingPrice,
    lipSyncPrice,
    textOnBodyPrice,
    tippingPrice,
    promptGroup,
    personality,
    personalitySexting,
    timezone,
  } = requestData;

  const formData = new FormData();
  if (profilePhoto && profilePhoto.startsWith('data:')) {
    const profilePhotoFile = base64ToFile(profilePhoto, 'profile-photo');
    formData.append('file', profilePhotoFile, profilePhotoFile.name);
  }

  formData.append('name', name);
  formData.append('nickname', nickname);
  formData.append('email', email);
  formData.append('dateOfBirth', dateOfBirth);
  formData.append('paymentRatio', paymentRatio);
  formData.append('voiceResponseRatio', voiceResponseRatio);
  formData.append('pricing[dickRate]', dickRatePrice);
  formData.append('pricing[sexting]', sextingPrice);
  formData.append('pricing[lipSync]', lipSyncPrice);
  formData.append('pricing[textOnBody]', textOnBodyPrice);
  formData.append('pricing[tipping]', tippingPrice);
  if (promptGroup) {
    formData.append('promptGroupId', promptGroup.value);
  }
  if (personality) {
    formData.append('personality', personality);
  }
  if (personalitySexting) {
    formData.append('personalitySexting', personalitySexting);
  }
  formData.append('timezone', timezone.value);
  if (telegramChannelUrl) {
    formData.append('telegramChannelUrl', telegramChannelUrl);
  }

  formData.append('voiceProvider[cartesia][voiceId]', cartesiaId);

  return await influencerService.editInfluencer(selectedInfluencerId, formData);
});

export const editPrompts = createAsyncThunk('influencer/editPrompts', async (requestData, { getState }) => {
  const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
  let body = requestData;
  if (!requestData.isVoiceCallEnabled) {
    delete body.prompt.male.voiceCall;
  }
  body.prompt.female = body.prompt.male;
  return await influencerService.editPrompts(selectedInfluencerId, requestData);
});

export const fetchInfluencerAlbumMedia = createAsyncThunk('influencer/fetchInfluencerAlbumMedia', async requestData => {
  const { influencerId, mediaType, mediaCategory } = requestData;
  return await influencerService.fetchInfluencerAlbumMedia(influencerId, mediaType, mediaCategory);
});

export const editInfluencerAlbumMedia = createAsyncThunk(
  'influencer/editInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    const { albumMediaId, caption, mediaType, fileId, aiDescription, tag, cost, mediaCategory, contentId } =
      requestData;

    const body = {
      caption,
      fileId,
      ...(mediaCategory === MEDIA_CATEGORY.PREMIUM && { aiDescription, tag, cost, ...(contentId && { contentId }) }),
    };

    return await influencerService.editInfluencerAlbumMedia(
      selectedInfluencerId,
      albumMediaId,
      mediaCategory,
      body,
      mediaType,
    );
  },
);

export const uploadInfluencerAlbumMedia = createAsyncThunk(
  'influencer/uploadInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    const { albumMedia, cost, aiDescription, caption, mediaType, fileId, mediaCategory, tag } = requestData;

    const formData = new FormData();
    formData.append('file', albumMedia.file);
    if (mediaCategory === MEDIA_CATEGORY.PREMIUM) {
      formData.append('cost', cost);
      formData.append('aiDescription', aiDescription);
      formData.append('tag', tag);
    }
    formData.append('caption', caption);
    formData.append('fileId', fileId);

    return await influencerService.uploadInfluencerAlbumMedia(selectedInfluencerId, mediaCategory, formData, mediaType);
  },
);

export const fetchInfluencerVariables = createAsyncThunk('influencer/fetchInfluencerVariables', async () => {
  return await influencerService.fetchInfluencerVariables();
});

export const changeAlbumMediaPosition = createAsyncThunk('influencer/changeAlbumMediaPosition', async requestData => {
  const { influencerId, mediaType, mediaPositions } = requestData;
  return await influencerService.changeAlbumMediaPosition(influencerId, mediaType, mediaPositions);
});

export const fetchInfluencerPremiumAlbumMedia = createAsyncThunk(
  'influencer/fetchInfluencerPremiumAlbumMedia',
  async (_, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.fetchInfluencerPremiumAlbumMedia(selectedInfluencerId);
  },
);

export const createMediaBundle = createAsyncThunk('influencer/createMediaBundle', async (requestData, { getState }) => {
  const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
  const { media, sale, caption, aiDescription } = requestData;
  return await influencerService.createMediaBundle(selectedInfluencerId, media, sale, caption, aiDescription);
});

export const fetchInfluencerMediaBundles = createAsyncThunk(
  'influencer/fetchInfluencerMediaBundles',
  async (_, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.fetchInfluencerMediaBundles(selectedInfluencerId);
  },
);

export const fetchMediaBundleById = createAsyncThunk(
  'influencer/fetchMediaBundleById',
  async ({ mediaBundleId }, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.fetchMediaBundleById(selectedInfluencerId, mediaBundleId);
  },
);

export const editMediaBundle = createAsyncThunk('influencer/editMediaBundle', async (requestData, { getState }) => {
  const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
  const { caption, aiDescription, mediaBundleId, sale } = requestData;
  return await influencerService.editMediaBundle(selectedInfluencerId, mediaBundleId, caption, aiDescription, sale);
});

export const changeMediaBundlePosition = createAsyncThunk(
  'influencer/changeMediaBundlePosition',
  async (requestData, { getState }) => {
    const { mediaPositions } = requestData;
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;

    return await influencerService.changeMediaBundlePosition(selectedInfluencerId, mediaPositions);
  },
);

export const deleteInfluencerAlbumMedia = createAsyncThunk(
  'influencer/deleteInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    const { albumMediaId, mediaType, mediaCategory, mediaTag } = requestData;

    return await influencerService.deleteInfluencerAlbumMedia(
      selectedInfluencerId,
      albumMediaId,
      mediaType,
      mediaCategory,
      mediaTag,
    );
  },
);

export const deleteMediaBundle = createAsyncThunk(
  'influencer/deleteMediaBundle',
  async ({ mediaBundleId }, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.deleteMediaBundle(selectedInfluencerId, mediaBundleId);
  },
);

export const cancelInfluencerMassDM = createAsyncThunk('influencer/cancelInfluencerMassDM', async (_, { getState }) => {
  const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
  return await influencerService.cancelInfluencerMassDM(selectedInfluencerId);
});

export const triggerInfluencerMassDM = createAsyncThunk(
  'influencer/triggerInfluencerMassDM',
  async ({ albumMediaType }, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.triggerInfluencerMassDM(selectedInfluencerId, albumMediaType);
  },
);

export const fetchCaptionRecommendations = createAsyncThunk(
  'influencer/fetchCaptionRecommendations',
  async (requestData, { getState }) => {
    const { mediaId, mediaType } = requestData;
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.fetchCaptionRecommendations(selectedInfluencerId, mediaId, mediaType);
  },
);

export const pingAdminsWithMedia = createAsyncThunk(
  'influencer/pingAdminsWithMedia',
  async (requestData, { getState }) => {
    const { fileId, mediaType, notify } = requestData;
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.pingAdminsWithMedia(selectedInfluencerId, fileId, mediaType, notify);
  },
);
