import React from 'react';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { ModalContainer, StyledImage, StyledVideo } from './PreviewAlbumMediaModal.styles';

import { MEDIA_TYPE } from '../../../utils/constants';

const PreviewAlbumMediaModal = ({ modalRef, type, url, setSelectedUrl }) => {
  return (
    <FormModal
      ref={modalRef}
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => setSelectedUrl(null)}
      increasedWidth
      shouldCloseOnOverlayClick>
      <ModalContainer>
        {type === MEDIA_TYPE.PHOTO ? (
          <StyledImage src={url} alt="premium-photo-media" onClick={() => {}} />
        ) : (
          <StyledVideo controls src={url} onClick={() => {}} />
        )}
      </ModalContainer>
    </FormModal>
  );
};

export default PreviewAlbumMediaModal;
