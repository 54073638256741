import React, { Fragment } from 'react';
import { ActionTableBodyItem, TableBodyItem, TableItemImage } from '../../Table.styles';
import { CircleArrowIcon, DownloadIcon } from './InfluencerRequestItem.styles';

const InfluencerRequestItem = ({ email, name, profilePhoto, onDownloadClick, onContinueClick }) => {
  return (
    <Fragment>
      <TableBodyItem>
        <TableItemImage src={profilePhoto} alt="influencer-request-profile" />
        <p>{name || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{email || '-'}</p>
      </TableBodyItem>
      <ActionTableBodyItem>
        <DownloadIcon onClick={onDownloadClick} />
        <CircleArrowIcon onClick={onContinueClick} />
      </ActionTableBodyItem>
    </Fragment>
  );
};

export default InfluencerRequestItem;
