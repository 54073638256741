import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import { createWithdrawalBuilder, fetchWithdrawalsBuilder } from './builder';

const INIT_STATE = {
  fetchWithdrawalsInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  pending: {
    createWithdrawal: false,
  },
};

export const withdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    fetchWithdrawalsBuilder(builder);
    createWithdrawalBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const selectFetchWithdrawalsInfo = state => state.withdrawalInfo.fetchWithdrawalsInfo;
export const selectCreateWithdrawalPending = state => state.withdrawalInfo.pending.createWithdrawal;

export default withdrawalSlice.reducer;
