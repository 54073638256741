import styled from 'styled-components';
import { MainButton } from '../../../../components/utils/buttons/Button/Button';
import { Text14Regular } from '../../../../components/utils/texts/text.styles';

export const EditInfluencerButton = styled(MainButton)`
  margin-top: 2.4rem;
`;

export const ActiveProviderText = styled(Text14Regular)`
  margin: 0.8rem 0;
`;
