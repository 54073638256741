import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  cursor: ${props => (props.$cursorPointer ? 'pointer' : 'auto')};
`;

export const SkeletonContainer = styled(Skeleton)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

export const VideoComponent = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
