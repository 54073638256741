import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { useSearchParams } from 'react-router-dom';
import { selectFetchWithdrawalsInfo } from '../../store/slices/withdrawal/slice';
import { fetchWithdrawals } from '../../store/slices/withdrawal/asyncThunks';
import { AddWithdrawalButton, Header, Row, SearchInput, Title } from './WithdrawalsPage.styles';
import { Table } from '../../components';
import CreateWithdrawalFormModal from '../../components/modals/CreateWithdrawalFormModal/CreateWithdrawalFormModal';
import WithdrawalItem from '../../components/Table/items/WithdrawalItem/WithdrawalItem';

const WithdrawalsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get('page') || 1;
  const search = searchParams.get('search');
  const [searchValue, setSearchValue] = useState(() => search ?? '');
  const { data, total, pending } = useSelector(selectFetchWithdrawalsInfo);

  const createWithdrawalModalRef = useRef();
  const dispatch = useDispatch();

  const changeHandler = useCallback(
    text => {
      searchParams.set('search', text);
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams],
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  useEffect(() => {
    if (page > 0) {
      dispatch(fetchWithdrawals({ page, search: search ?? '' }));
    }
  }, [dispatch, page, search]);

  const setPage = page => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  const tableHeader = [{ label: 'Influencer' }, { label: 'Amount' }, { label: 'Status' }, { label: 'Created At' }];

  return (
    <>
      <Header>
        <Title>Withdrawals</Title>
        <Row>
          <SearchInput
            value={searchValue}
            onChange={event => {
              setSearchValue(event.target.value);
              debouncedChangeHandler(event.target.value);
            }}
            placeholder="Search"
            hideErrorMessage
          />
          <AddWithdrawalButton title="Create Withdrawal" onClick={() => createWithdrawalModalRef.current.show()} />
        </Row>
      </Header>
      <Table
        headerInformation={tableHeader}
        dataLoading={pending}
        totalCount={total}
        currentPage={page}
        perPage={10}
        setPage={setPage}
        emptyDataText={'No data'}
        fullHeight>
        {data.map(withdrawal => (
          <WithdrawalItem
            key={withdrawal.id}
            status={withdrawal.status}
            amount={withdrawal.amount}
            createdAt={withdrawal.createdAt}
            influencerName={withdrawal.influencer.name}
          />
        ))}
      </Table>
      <CreateWithdrawalFormModal
        modalRef={createWithdrawalModalRef}
        onSuccess={() => {
          if (page > 0) {
            dispatch(fetchWithdrawals({ page, search: search ?? '' }));
          }
        }}
      />
    </>
  );
};

export default WithdrawalsPage;
