import styled from 'styled-components';
import Video from '../../utils/Video/Video';
import Image from '../../utils/Image/Image';

export const ModalContainer = styled.div`
  display: flex;

  flex-direction: column;
`;

export const StyledVideo = styled(Video)`
  aspect-ratio: unset;
  height: 58rem;
`;

export const StyledImage = styled(Image)`
  aspect-ratio: unset;
  height: 58rem;
`;
