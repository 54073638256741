import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEditInfluencerPending,
  selectFetchInfluencerVariablesInfoData,
  selectSelectedInfluencer,
} from '../../../../store/slices/influencer/slice';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditInfluencerButton } from './InfluencerEditTab.styles';
import { UPLOAD_FILE_CONSTRAINTS, VOICE_PROVIDER_TITLE } from '../../../../utils/constants';
import { editInfluencer } from '../../../../store/slices/influencer/asyncThunks';
import { notifyError, notifyInfo } from '../../../../utils/notify';
import { FlexFooter, FlexMain } from '../../../../components/utils/utils.styles';
import { editInfluencerFormSchema } from '../../../../forms/schemas/influencer.schema';
import { FormDatePicker, FormFileInput, FormInput, FormNumericInput } from '../../../../forms/form.styles';
import CropImageModal from '../../../../components/utils/modals/CropImageModal/CropImageModal';
import HandleVoiceProvidersModal from '../../../../components/modals/HandleVoiceProvidersModal/HandleVoiceProvidersModal';
import Select from '../../../../components/utils/selects/Select/Select';

function InfluencerEditTab() {
  const selectedInfluencer = useSelector(selectSelectedInfluencer);
  const editInfluencerPending = useSelector(selectEditInfluencerPending);
  const cropImageRef = useRef();
  const handleVoiceProvidersModalRef = useRef();
  const dispatch = useDispatch();
  const influencerVariables = useSelector(selectFetchInfluencerVariablesInfoData);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      profilePhoto: selectedInfluencer.profilePhoto || null,
      name: selectedInfluencer.name || '',
      nickname: selectedInfluencer.nickname || '',
      email: selectedInfluencer.email || '',
      dateOfBirth: selectedInfluencer.dateOfBirth || null,
      paymentRatio: selectedInfluencer.paymentRatio || 0.5,
      voiceResponseRatio: !isNaN(selectedInfluencer.voiceResponseRatio) ? selectedInfluencer.voiceResponseRatio : 0.5,
      telegramChannelUrl: selectedInfluencer.telegram.channelUrl || '',
      dickRatePrice: selectedInfluencer.pricing.dickRate || 20,
      sextingPrice: selectedInfluencer.pricing.sexting || 20,
      lipSyncPrice: selectedInfluencer.pricing.lipSync || 20,
      tippingPrice: selectedInfluencer.pricing.tipping || 299,
      textOnBodyPrice: selectedInfluencer.pricing.textOnBody || 20,
      cartesiaId: '',
      promptGroup: null,
      personality: selectedInfluencer.personality || '',
      personalitySexting: selectedInfluencer.personalitySexting || '',
      timezone: null,
    },
    delayError: 300,
    resolver: yupResolver(editInfluencerFormSchema),
  });

  const watchProfilePhoto = watch('profilePhoto');
  const watchVoiceProviders = watch('voiceProviders');

  useEffect(() => {
    if (selectedInfluencer) {
      const voiceProvider = selectedInfluencer.voiceProvider;
      setValue('voiceProviders.active', {
        value: voiceProvider.active,
        label: VOICE_PROVIDER_TITLE[voiceProvider.active],
      });
      if (selectedInfluencer.promptGroup) {
        setValue('promptGroup', {
          value: selectedInfluencer.promptGroup.id,
          label: selectedInfluencer.promptGroup.name,
        });
      }
      if (selectedInfluencer.timezone) {
        setValue('timezone', {
          value: selectedInfluencer.timezone,
          label: selectedInfluencer.timezone,
        });
      }
      if (voiceProvider.cartesia) {
        setValue('cartesiaId', voiceProvider.cartesia.voiceId);
      } else {
      }
    }
  }, [selectedInfluencer, setValue]);

  const promptGroupOptions = useMemo(() => {
    if (!influencerVariables) return [];
    return influencerVariables.promptGroups.map(promptGroup => ({
      value: promptGroup.id,
      label: promptGroup.name,
    }));
  }, [influencerVariables]);

  const timezoneOptions = useMemo(() => {
    if (!influencerVariables) return [];
    return influencerVariables.timezones.map(timezone => ({
      value: timezone.id,
      label: timezone.name,
    }));
  }, [influencerVariables]);

  useEffect(() => {
    if (watchProfilePhoto) {
      if (typeof watchProfilePhoto !== 'string') {
        cropImageRef.current.show();
      }
    }
  }, [watchProfilePhoto]);

  const onSubmit = data => {
    dispatch(editInfluencer(data))
      .unwrap()
      .then(_ => {
        notifyInfo('Influencer updated successfully!');
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <>
      <FlexMain>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="profilePhoto"
            control={control}
            render={({ field }) => {
              return (
                <FormFileInput
                  label="Profile photo"
                  value={field.value}
                  onChange={field.onChange}
                  type={UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO}
                  error={errors.profilePhoto?.message}
                />
              );
            }}
          />
          <FormInput label="Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
          <Controller
            name="promptGroup"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  label="Prompt Group"
                  selectedOption={field.value}
                  handleChange={field.onChange}
                  options={promptGroupOptions}
                  isClearable
                />
              );
            }}
          />
          <FormInput
            label="Personality"
            placeholder="Enter personality"
            {...register('personality')}
            error={errors.personality?.message}
            type="textarea"
          />
          <FormInput
            label="Personality Sexting"
            placeholder="Enter personality sexting"
            {...register('personalitySexting')}
            error={errors.personalitySexting?.message}
            type="textarea"
          />
          <FormInput
            label="Nickname"
            placeholder="Enter nickname"
            {...register('nickname')}
            error={errors.nickname?.message}
          />
          <FormInput label="Email" placeholder="Enter email" {...register('email')} error={errors.email?.message} />
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field }) => {
              return (
                <FormDatePicker
                  label="Date of birth"
                  placeholder="Enter date of birth"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.dateOfBirth?.message}
                />
              );
            }}
          />
          <FormInput
            label="Telegram Channel URL"
            placeholder="Enter Telegram Channel URL"
            {...register('telegramChannelUrl')}
            error={errors.telegramChannelUrl?.message}
          />
          <FormInput
            label="Cartesia ID"
            placeholder="Cartesia ID"
            {...register('cartesiaId')}
            error={errors.cartesiaId?.message}
          />
          <Controller
            name="timezone"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  label="Timezone"
                  selectedOption={field.value}
                  handleChange={field.onChange}
                  options={timezoneOptions}
                  error={errors.timezone?.message}
                />
              );
            }}
          />
          <FormNumericInput
            label="Payment ratio"
            {...register('paymentRatio', {
              valueAsNumber: true,
            })}
            error={errors.paymentRatio?.message}
          />
          <FormNumericInput
            label="Voice response ratio"
            {...register('voiceResponseRatio', {
              valueAsNumber: true,
            })}
            error={errors.voiceResponseRatio?.message}
          />
          <FormNumericInput
            label="Dick Rate Price"
            {...register('dickRatePrice', {
              valueAsNumber: true,
            })}
            error={errors.dickRatePrice?.message}
          />
          <FormNumericInput
            label="Sexting Price"
            {...register('sextingPrice', {
              valueAsNumber: true,
            })}
            error={errors.sextingPrice?.message}
          />
          <FormNumericInput
            label="Lip Sync Price"
            {...register('lipSyncPrice', {
              valueAsNumber: true,
            })}
            error={errors.lipSyncPrice?.message}
          />
          <FormNumericInput
            label="Text On Body Price"
            {...register('textOnBodyPrice', {
              valueAsNumber: true,
            })}
            error={errors.textOnBodyPrice?.message}
          />
          <FormNumericInput
            label="Tipping Price"
            {...register('tippingPrice', {
              valueAsNumber: true,
            })}
            error={errors.tippingPrice?.message}
          />
        </form>
      </FlexMain>
      <FlexFooter>
        <EditInfluencerButton
          title="Edit influencer"
          onClick={() => handleSubmit(onSubmit)()}
          isLoading={editInfluencerPending}
        />
      </FlexFooter>
      <HandleVoiceProvidersModal
        modalRef={handleVoiceProvidersModalRef}
        onSetVoiceProviders={data => setValue('voiceProviders', data, { shouldValidate: true })}
        voiceProviders={watchVoiceProviders}
      />
      <CropImageModal
        ref={cropImageRef}
        onClose={() => {
          setValue('profilePhoto', null);
          cropImageRef.current.hide();
        }}
        onComplete={croppedImage => {
          setValue('profilePhoto', croppedImage);
          cropImageRef.current.hide();
        }}
        originalImage={getValues('profilePhoto')}
      />
    </>
  );
}

export default InfluencerEditTab;
