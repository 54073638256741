import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/a/invitation-codes';

class InvitationCodeService {
  fetchInvitationCodes = async (page, search) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching invitation codes.');
    }
  };

  createInvitationCode = async body => {
    try {
      const response = await axiosApiClient.post(BASE_URL, body);
      throwErrorIfWrongStatus(response, 201);
    } catch (err) {
      throw handleError(err, 'Error occurred while creating invitation code.');
    }
  };

  deleteInvitationCode = async id => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/${id}`);
      throwErrorIfWrongStatus(response, 200);
      return id;
    } catch (err) {
      throw handleError(err, 'Error occurred while deleting invitation code.');
    }
  };
}

const invitationCodeService = new InvitationCodeService();
export default invitationCodeService;
