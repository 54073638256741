import { createAsyncThunk } from '@reduxjs/toolkit';
import invitationCodeService from '../../../api/services/invitationCode';

export const fetchInvitationCodes = createAsyncThunk(
  'invitation-code/fetchInvitationCodes',
  async ({ page, search }) => {
    return await invitationCodeService.fetchInvitationCodes(page, search);
  },
);

export const createInvitationCode = createAsyncThunk('invitation-code/createInvitationCode', async requestData => {
  return await invitationCodeService.createInvitationCode(requestData);
});

export const deleteInvitationCode = createAsyncThunk('invitation-code/deleteInvitationCode', async id => {
  return await invitationCodeService.deleteInvitationCode(id);
});
