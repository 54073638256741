import styled from 'styled-components';
import { Text24Bold } from '../../../../components/utils/texts/text.styles';
import { MainButton } from '../../../../components/utils/buttons/Button/Button';

export const Title = styled(Text24Bold)``;

export const AddNewMediaBundleButton = styled(MainButton)`
  width: 24rem;
  height: 100%;
  font-size: 1.5rem;
`;

export const MediaBundleCard = styled.div`
  border-radius: 1rem;
  padding: 1.4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const MediaBundlesContainer = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
`;
