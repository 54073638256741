import { createAsyncThunk } from '@reduxjs/toolkit';
import influencerRequestService from '../../../api/services/influencerRequest';

export const fetchInfluencerRequests = createAsyncThunk(
  'influencer-request/fetchInfluencerRequests',
  async ({ page, search }) => {
    return await influencerRequestService.fetchInfluencerRequests(page, search);
  },
);

export const fetchInfluencerRequestById = createAsyncThunk(
  'influencer-request/fetchInfluencerRequestById',
  async requestData => {
    return await influencerRequestService.fetchInfluencerRequestById(requestData);
  },
);
