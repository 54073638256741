import React, { Fragment } from 'react';
import { ActionTableBodyItem, TableBodyItem } from '../../Table.styles';
import { DeleteIcon } from './InvitationCodeItem.styles';

const InvitationCodeItem = ({ onDelete, code, influencerRequest, createdAt }) => {
  return (
    <Fragment>
      <TableBodyItem>
        <p>{code || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{influencerRequest?.email || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{new Date(createdAt).toLocaleString()}</p>
      </TableBodyItem>
      <ActionTableBodyItem>{!influencerRequest?.email && <DeleteIcon onClick={onDelete} />}</ActionTableBodyItem>
    </Fragment>
  );
};

export default InvitationCodeItem;
