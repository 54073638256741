import { MEDIA_CATEGORY } from '../../../utils/constants';
import {
  fetchInfluencers,
  editInfluencer,
  fetchInfluencerById,
  fetchInfluencerAlbumMedia,
  createInfluencer,
  fetchInfluencerVariables,
  editPrompts,
  fetchInfluencerPremiumAlbumMedia,
  createMediaBundle,
  fetchInfluencerMediaBundles,
  fetchMediaBundleById,
  deleteInfluencerAlbumMedia,
  editInfluencerAlbumMedia,
  uploadInfluencerAlbumMedia,
  deleteMediaBundle,
  triggerInfluencerMassDM,
  cancelInfluencerMassDM,
  fetchCaptionRecommendations,
  editMediaBundle,
  pingAdminsWithMedia,
} from './asyncThunks';

export const fetchInfluencersBuilder = builder => {
  builder.addCase(fetchInfluencers.pending, state => {
    state.fetchInfluencersInfo.pending = true;
  });
  builder.addCase(fetchInfluencers.fulfilled, (state, { payload }) => {
    const { data, total } = payload;
    state.fetchInfluencersInfo.pending = false;
    state.fetchInfluencersInfo.data = data;
    state.fetchInfluencersInfo.total = total;
  });
  builder.addCase(fetchInfluencers.rejected, state => {
    state.fetchInfluencersInfo.pending = false;
  });
};

export const fetchInfluencerByIdBuilder = builder => {
  builder.addCase(fetchInfluencerById.pending, state => {
    state.selectedInfluencerInfo.pending = true;
  });
  builder.addCase(fetchInfluencerById.fulfilled, (state, { payload }) => {
    state.selectedInfluencerInfo.influencer = payload;
    state.selectedInfluencerInfo.pending = false;
  });
  builder.addCase(fetchInfluencerById.rejected, state => {
    state.selectedInfluencerInfo.pending = false;
  });
};

export const createInfluencerBuilder = builder => {
  builder.addCase(createInfluencer.pending, state => {
    state.pending.createInfluencer = true;
  });
  builder.addCase(createInfluencer.fulfilled, (state, { payload }) => {
    state.pending.createInfluencer = false;
    state.fetchInfluencerVariablesInfo.data.businessConnections =
      state.fetchInfluencerVariablesInfo.data.businessConnections.filter(
        businessConnection => businessConnection.id !== payload,
      );
  });
  builder.addCase(createInfluencer.rejected, state => {
    state.pending.createInfluencer = false;
  });
};

export const editInfluencerBuilder = builder => {
  builder.addCase(editInfluencer.pending, state => {
    state.pending.editInfluencer = true;
  });
  builder.addCase(editInfluencer.fulfilled, (state, { payload }) => {
    state.pending.editInfluencer = false;
    state.selectedInfluencerInfo.influencer = payload;
  });
  builder.addCase(editInfluencer.rejected, state => {
    state.pending.editInfluencer = false;
  });
};

export const editPromptsBuilder = builder => {
  builder.addCase(editPrompts.pending, state => {
    state.pending.editPrompts = true;
  });
  builder.addCase(editPrompts.fulfilled, (state, { payload }) => {
    state.pending.editPrompts = false;
    state.selectedInfluencerInfo.influencer = payload;
  });
  builder.addCase(editPrompts.rejected, state => {
    state.pending.editPrompts = false;
  });
};

export const fetchInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(fetchInfluencerAlbumMedia.pending, state => {
    state.fetchInfluencerAlbumMediaInfo.pending = true;
  });
  builder.addCase(fetchInfluencerAlbumMedia.fulfilled, (state, { payload }) => {
    const { data } = payload;
    state.fetchInfluencerAlbumMediaInfo.pending = false;
    state.fetchInfluencerAlbumMediaInfo.data = data;
  });
  builder.addCase(fetchInfluencerAlbumMedia.rejected, state => {
    state.fetchInfluencerAlbumMediaInfo.pending = false;
  });
};

export const editInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(editInfluencerAlbumMedia.pending, state => {
    state.pending.editAlbumMedia = true;
  });
  builder.addCase(editInfluencerAlbumMedia.fulfilled, (state, { payload }) => {
    const { media, mediaCategory } = payload;
    if (mediaCategory === MEDIA_CATEGORY.PREMIUM) {
      const tagData = state.fetchInfluencerAlbumMediaInfo.data[media.tag];
      let oldAlbumMedia = null;
      for (const dataByTag of Object.values(state.fetchInfluencerAlbumMediaInfo.data)) {
        for (const albumMediaByTag of dataByTag) {
          if (media.id === albumMediaByTag.id && media.tag !== albumMediaByTag.tag) {
            oldAlbumMedia = albumMediaByTag;
          }
        }
      }

      if (tagData) {
        if (oldAlbumMedia) {
          state.fetchInfluencerAlbumMediaInfo.data[media.tag] = [
            ...state.fetchInfluencerAlbumMediaInfo.data[media.tag],
            media,
          ];
          if (oldAlbumMedia) {
            state.fetchInfluencerAlbumMediaInfo.data[oldAlbumMedia.tag] = state.fetchInfluencerAlbumMediaInfo.data[
              oldAlbumMedia.tag
            ].filter(a => a.id !== oldAlbumMedia.id);
          }
        } else {
          state.fetchInfluencerAlbumMediaInfo.data[media.tag] = state.fetchInfluencerAlbumMediaInfo.data[media.tag].map(
            albumMedia => {
              if (albumMedia.id !== media.id) {
                return albumMedia;
              } else {
                return media;
              }
            },
          );
        }
      } else {
        state.fetchInfluencerAlbumMediaInfo.data[media.tag] = [media];
        if (oldAlbumMedia) {
          state.fetchInfluencerAlbumMediaInfo.data[oldAlbumMedia.tag] = state.fetchInfluencerAlbumMediaInfo.data[
            oldAlbumMedia.tag
          ].filter(a => a.id !== oldAlbumMedia.id);
        }
      }
    } else {
      state.fetchInfluencerAlbumMediaInfo.data = state.fetchInfluencerAlbumMediaInfo.data.map(albumMedia => {
        if (albumMedia.id !== media.id) {
          return albumMedia;
        } else {
          return media;
        }
      });
    }

    state.pending.editAlbumMedia = false;
  });
  builder.addCase(editInfluencerAlbumMedia.rejected, state => {
    state.pending.editAlbumMedia = false;
  });
};

export const uploadInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(uploadInfluencerAlbumMedia.pending, state => {
    state.pending.uploadAlbumMedia = true;
  });
  builder.addCase(uploadInfluencerAlbumMedia.fulfilled, (state, { payload }) => {
    const { media, mediaCategory } = payload;
    if (mediaCategory === MEDIA_CATEGORY.PREMIUM) {
      const tagData = state.fetchInfluencerAlbumMediaInfo.data[media.tag];
      if (tagData) {
        state.fetchInfluencerAlbumMediaInfo.data[media.tag] = [
          ...state.fetchInfluencerAlbumMediaInfo.data[media.tag],
          media,
        ];
      } else {
        state.fetchInfluencerAlbumMediaInfo.data[media.tag] = [media];
      }
    } else {
      state.fetchInfluencerAlbumMediaInfo.data = [...state.fetchInfluencerAlbumMediaInfo.data, media];
    }
    state.pending.uploadAlbumMedia = false;
  });
  builder.addCase(uploadInfluencerAlbumMedia.rejected, state => {
    state.pending.uploadAlbumMedia = false;
  });
};

export const fetchInfluencerVariablesBuilder = builder => {
  builder.addCase(fetchInfluencerVariables.pending, state => {
    state.fetchInfluencerVariablesInfo.pending = true;
  });
  builder.addCase(fetchInfluencerVariables.fulfilled, (state, { payload }) => {
    state.fetchInfluencerVariablesInfo.pending = false;
    state.fetchInfluencerVariablesInfo.data = payload;
  });
  builder.addCase(fetchInfluencerVariables.rejected, state => {
    state.fetchInfluencerVariablesInfo.pending = false;
  });
};

export const fetchInfluencerPremiumAlbumMediaBuilder = builder => {
  builder.addCase(fetchInfluencerPremiumAlbumMedia.pending, state => {
    state.fetchInfluencerPremiumAlbumMediaInfo.pending = true;
  });
  builder.addCase(fetchInfluencerPremiumAlbumMedia.fulfilled, (state, { payload }) => {
    state.fetchInfluencerPremiumAlbumMediaInfo.pending = false;
    state.fetchInfluencerPremiumAlbumMediaInfo.data = payload;
  });
  builder.addCase(fetchInfluencerPremiumAlbumMedia.rejected, state => {
    state.fetchInfluencerPremiumAlbumMediaInfo.pending = false;
  });
};

export const createMediaBundleBuilder = builder => {
  builder.addCase(createMediaBundle.pending, state => {
    state.pending.createMediaBundle = true;
  });
  builder.addCase(createMediaBundle.fulfilled, state => {
    state.pending.createMediaBundle = false;
  });
  builder.addCase(createMediaBundle.rejected, state => {
    state.pending.createMediaBundle = false;
  });
};

export const fetchInfluencerMediaBundlesBuilder = builder => {
  builder.addCase(fetchInfluencerMediaBundles.pending, state => {
    state.fetchInfluencerMediaBundlesInfo.pending = true;
  });
  builder.addCase(fetchInfluencerMediaBundles.fulfilled, (state, { payload }) => {
    state.fetchInfluencerMediaBundlesInfo.pending = false;
    state.fetchInfluencerMediaBundlesInfo.data = payload;
  });
  builder.addCase(fetchInfluencerMediaBundles.rejected, state => {
    state.fetchInfluencerMediaBundlesInfo.pending = false;
  });
};

export const fetchMediaBundleByIdBuilder = builder => {
  builder.addCase(fetchMediaBundleById.pending, state => {
    state.pending.fetchMediaBundleById = true;
  });
  builder.addCase(fetchMediaBundleById.fulfilled, state => {
    state.pending.fetchMediaBundleById = false;
  });
  builder.addCase(fetchMediaBundleById.rejected, state => {
    state.pending.fetchMediaBundleById = false;
  });
};

export const editMediaBundleBuilder = builder => {
  builder.addCase(editMediaBundle.pending, state => {
    state.pending.editMediaBundle = true;
  });
  builder.addCase(editMediaBundle.fulfilled, state => {
    state.pending.editMediaBundle = false;
  });
  builder.addCase(editMediaBundle.rejected, state => {
    state.pending.editMediaBundle = false;
  });
};

export const deleteInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(deleteInfluencerAlbumMedia.pending, state => {
    state.pending.deleteAlbumMedia = true;
  });
  builder.addCase(deleteInfluencerAlbumMedia.fulfilled, (state, { payload }) => {
    const { albumMediaId, mediaCategory, mediaTag } = payload;
    if (mediaCategory === MEDIA_CATEGORY.PREMIUM) {
      state.fetchInfluencerAlbumMediaInfo.data[mediaTag] = state.fetchInfluencerAlbumMediaInfo.data[mediaTag].filter(
        albumMedia => {
          return albumMedia.id !== albumMediaId;
        },
      );
    } else {
      state.fetchInfluencerAlbumMediaInfo.data = state.fetchInfluencerAlbumMediaInfo.data.filter(albumMedia => {
        return albumMedia.id !== albumMediaId;
      });
    }
    state.pending.deleteAlbumMedia = false;
  });
  builder.addCase(deleteInfluencerAlbumMedia.rejected, state => {
    state.pending.deleteAlbumMedia = false;
  });
};

export const deleteMediaBundleBuilder = builder => {
  builder.addCase(deleteMediaBundle.pending, state => {
    state.pending.deleteMediaBundle = true;
  });
  builder.addCase(deleteMediaBundle.fulfilled, (state, { payload }) => {
    const { mediaBundleId } = payload;
    state.fetchInfluencerMediaBundlesInfo.data = state.fetchInfluencerMediaBundlesInfo.data.filter(
      mediaBundle => mediaBundle.id !== mediaBundleId,
    );
    state.pending.deleteMediaBundle = false;
  });
  builder.addCase(deleteMediaBundle.rejected, state => {
    state.pending.deleteMediaBundle = false;
  });
};

export const triggerInfluencerMassDMBuilder = builder => {
  builder.addCase(triggerInfluencerMassDM.pending, state => {
    state.pending.triggerInfluencerMassDM = true;
  });
  builder.addCase(triggerInfluencerMassDM.fulfilled, (state, { payload }) => {
    state.pending.triggerInfluencerMassDM = false;
    state.selectedInfluencerInfo.influencer.massDM = payload.massDM;
  });
  builder.addCase(triggerInfluencerMassDM.rejected, state => {
    state.pending.triggerInfluencerMassDM = false;
  });
};

export const cancelInfluencerMassDMBuilder = builder => {
  builder.addCase(cancelInfluencerMassDM.pending, state => {
    state.pending.cancelInfluencerMassDM = true;
  });
  builder.addCase(cancelInfluencerMassDM.fulfilled, state => {
    state.pending.cancelInfluencerMassDM = false;
    state.selectedInfluencerInfo.influencer.massDM = {
      jobId: null,
      timestamp: null,
    };
  });
  builder.addCase(cancelInfluencerMassDM.rejected, state => {
    state.pending.cancelInfluencerMassDM = false;
  });
};

export const fetchCaptionRecommendationsBuilder = builder => {
  builder.addCase(fetchCaptionRecommendations.pending, state => {
    state.pending.fetchCaptionRecommendations = true;
  });
  builder.addCase(fetchCaptionRecommendations.fulfilled, state => {
    state.pending.fetchCaptionRecommendations = false;
  });
  builder.addCase(fetchCaptionRecommendations.rejected, state => {
    state.pending.fetchCaptionRecommendations = false;
  });
};

export const pingAdminsWithMediaBuilder = builder => {
  builder.addCase(pingAdminsWithMedia.pending, state => {
    state.pending.pingAdminsWithMedia = true;
  });
  builder.addCase(pingAdminsWithMedia.fulfilled, state => {
    state.pending.pingAdminsWithMedia = false;
  });
  builder.addCase(pingAdminsWithMedia.rejected, state => {
    state.pending.pingAdminsWithMedia = false;
  });
};
