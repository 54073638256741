import React, { useState } from 'react';
import { SkeletonContainer, VideoComponent, VideoContainer } from './Video.styles';

const Video = ({ src, className, onClick, controls = false, disabled }) => {
  const [loaded, setLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setLoaded(true);
  };

  return (
    <VideoContainer className={className} onClick={onClick} $cursorPointer={!!onClick && !disabled}>
      {!loaded && <SkeletonContainer />}
      <VideoComponent
        controls={controls}
        onLoadedData={handleVideoLoaded}
        style={{ display: loaded ? 'block' : 'none' }}>
        <source src={src} type="video/mp4" />
      </VideoComponent>
    </VideoContainer>
  );
};

export default Video;
