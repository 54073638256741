import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  height: 70rem;
  flex-direction: column;
`;

export const PhotosContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const VideosContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 2rem;
`;

export const PhotosContent = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
`;
export const VideosContent = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
`;

export const MediaCardContainer = styled.div`
  width: 14rem;
  height: 14rem;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: ${({ $selected }) => ($selected ? '1px solid #843efe' : '1px solid transparent')};
  opacity: ${({ $disabled }) => ($disabled ? '0.5' : '1')};
  position: relative;
`;

export const StarContainer = styled.div`
  width: 9rem;
  height: 3rem;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #00000080;
  border-radius: 1rem;
`;
