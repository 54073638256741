import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/a/prompt-groups';

class PromptGroupService {
  fetchPromptGroups = async (page, search) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching prompt groups.');
    }
  };

  editPromptGroup = async (promptGroupId, name, prompt) => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/${promptGroupId}`, {
        name,
        prompt,
      });
      throwErrorIfWrongStatus(response, 200);
      const { promptGroup } = response.data.data;
      return { promptGroup };
    } catch (err) {
      throw handleError(err, 'Error occurred while editing prompt group.');
    }
  };
}

const promptGroupService = new PromptGroupService();
export default promptGroupService;
