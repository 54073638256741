import React from 'react';
import ReactSelect, { components } from 'react-select';
import { Container, selectStyles, SingleValueText } from './Select.styles';
import ErrorContainer from '../../ErrorContainer/ErrorContainer';
import { InputLabel } from '../../texts/text.styles';

const { Option, SingleValue, NoOptionsMessage } = components;

const Select = ({
  label,
  selectedOption,
  options,
  handleChange,
  isSearchable,
  placeholder,
  error,
  multiSelection = false,
  disabled,
  hideErrorMessage,
  className,
  isClearable,
}) => {
  return (
    <Container className={className}>
      {label && <InputLabel>{label}</InputLabel>}
      <ReactSelect
        styles={selectStyles()}
        options={options}
        value={selectedOption}
        onChange={handleChange}
        closeMenuOnSelect={!multiSelection}
        blurInputOnSelect={!multiSelection}
        isMulti={multiSelection}
        isDisabled={disabled}
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder={placeholder}
        components={{
          Option: props => IconOption(props),
          SingleValue: props => IconSingleValue(props),
          NoOptionsMessage: props => NoOptionsMessageComponent(props),
        }}
      />
      {!hideErrorMessage && <ErrorContainer errorText={error} />}
    </Container>
  );
};

const IconOption = props => {
  return (
    <Option {...props}>
      {props.data.icon}
      <SingleValueText>{props.data.label}</SingleValueText>
    </Option>
  );
};

const IconSingleValue = props => {
  return (
    <SingleValue {...props}>
      {props.data.icon}
      <SingleValueText>{props.data.label}</SingleValueText>
    </SingleValue>
  );
};

const NoOptionsMessageComponent = props => {
  return (
    <NoOptionsMessage {...props}>
      <SingleValueText>{props.children}</SingleValueText>
    </NoOptionsMessage>
  );
};

export default Select;
