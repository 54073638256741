import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchInfluencerRequests } from '../../store/slices/influencerRequest/asyncThunks';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectFetchInfluencerRequestsInfo } from '../../store/slices/influencerRequest/slice';
import { Header, Row, SearchInput, Title } from './InfluencerRequestsPage.styles';
import { Table } from '../../components';
import InfluencerRequestItem from '../../components/Table/items/InfluencerRequestItem/InfluencerRequestItem';
import { notifyError, notifyInfo } from '../../utils/notify';
import influencerRequestService from '../../api/services/influencerRequest';

const InfluencerRequestsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get('page') || 1;
  const search = searchParams.get('search');
  const [searchValue, setSearchValue] = useState(() => search ?? '');
  const { data, total, pending } = useSelector(selectFetchInfluencerRequestsInfo);
  const [downloadMediaLoading, setDownloadMediaLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changeHandler = useCallback(
    text => {
      searchParams.set('search', text);
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams],
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  useEffect(() => {
    if (page > 0) {
      dispatch(fetchInfluencerRequests({ page, search: search ?? '' }));
    }
  }, [dispatch, page, search]);

  const setPage = page => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  const tableHeader = [{ label: 'Name' }, { label: 'Email' }, { label: 'Actions', type: 'action' }];

  const onDownloadClick = async influencerRequestId => {
    try {
      notifyInfo('Downloading may take a few minutes. Please wait.');
      setDownloadMediaLoading(true);
      const response = await influencerRequestService.downloadZippedMediaFiles(influencerRequestId);
      const url = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'files.zip');
      document.body.appendChild(link);
      link.click();
      setDownloadMediaLoading(false);
    } catch (error) {
      setDownloadMediaLoading(false);
      notifyError(error);
    }
  };

  const onContinueClick = influencerRequestId => {
    navigate('/influencers/new', { state: { influencerRequestId } });
  };

  return (
    <>
      <Header>
        <Title>Influencer Requests</Title>
        <Row>
          <SearchInput
            value={searchValue}
            onChange={event => {
              setSearchValue(event.target.value);
              debouncedChangeHandler(event.target.value);
            }}
            placeholder="Search"
            hideErrorMessage
          />
        </Row>
      </Header>
      <Table
        headerInformation={tableHeader}
        dataLoading={pending || downloadMediaLoading}
        totalCount={total}
        currentPage={page}
        perPage={10}
        setPage={setPage}
        emptyDataText={'No data'}
        fullHeight>
        {data.map(influencerRequest => (
          <InfluencerRequestItem
            key={influencerRequest.id}
            email={influencerRequest.email}
            name={influencerRequest.name}
            profilePhoto={influencerRequest.profilePhoto}
            onDownloadClick={() => onDownloadClick(influencerRequest.id)}
            onContinueClick={() => onContinueClick(influencerRequest.id)}
          />
        ))}
      </Table>
    </>
  );
};

export default InfluencerRequestsPage;
