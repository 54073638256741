import React, { useEffect, useState } from 'react';
import { FlexMain } from '../../../../components/utils/utils.styles';
import { Text20Bold } from '../../../../components/utils/texts/text.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCancelInfluencerMassDMPending,
  selectSelectedInfluencer,
  selectTriggerInfluencerMassDMPending,
} from '../../../../store/slices/influencer/slice';
import { Container, StyledButton } from './InfluencerMassDmTab.styles';
import { useConfirmationModal } from '../../../../components/utils/modals/ConfirmationModal/ConfirmationModal';
import { notifyError, notifyInfo } from '../../../../utils/notify';
import { cancelInfluencerMassDM, triggerInfluencerMassDM } from '../../../../store/slices/influencer/asyncThunks';
import Select from '../../../../components/utils/selects/Select/Select';
import { ALBUM_MEDIA_TYPE } from '../../../../utils/constants';

const ALBUM_MEDIA_TYPE_TITLE = {
  [ALBUM_MEDIA_TYPE.PHOTO]: 'Photo',
  [ALBUM_MEDIA_TYPE.VIDEO]: 'Video',
  [ALBUM_MEDIA_TYPE.BUNDLE]: 'Bundle',
  [ALBUM_MEDIA_TYPE.RANDOM]: 'Random',
};

const InfluencerMassDmTab = () => {
  const selectedInfluencer = useSelector(selectSelectedInfluencer);
  const cancelInfluencerMassDMPending = useSelector(selectCancelInfluencerMassDMPending);
  const triggerInfluencerMassDMPending = useSelector(selectTriggerInfluencerMassDMPending);
  const [openModal, closeModal, setConfirmationModalLoader] = useConfirmationModal();
  const [albumMediaType, setAlbumMediaType] = useState({
    value: ALBUM_MEDIA_TYPE.RANDOM,
    label: ALBUM_MEDIA_TYPE_TITLE[ALBUM_MEDIA_TYPE.RANDOM],
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setConfirmationModalLoader(cancelInfluencerMassDMPending);
    // eslint-disable-next-line
  }, [cancelInfluencerMassDMPending]);

  useEffect(() => {
    setConfirmationModalLoader(triggerInfluencerMassDMPending);
    // eslint-disable-next-line
  }, [triggerInfluencerMassDMPending]);

  const onCancelMassDM = () => {
    openModal(
      'Cancel Mass DM',
      `Are you sure you want to cancel mass DM?`,
      'Cancel',
      () => {
        dispatch(cancelInfluencerMassDM())
          .unwrap()
          .then(() => {
            notifyInfo('Mass DM cancelled successfully!');
            closeModal();
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Close',
      () => {
        closeModal();
      },
    );
  };

  const onSendMassDM = () => {
    openModal(
      'Send Mass DM',
      `Are you sure you want to send mass DM?`,
      'Send',
      () => {
        dispatch(
          triggerInfluencerMassDM({
            ...(albumMediaType.value !== ALBUM_MEDIA_TYPE.RANDOM && { albumMediaType: albumMediaType?.value }),
          }),
        )
          .unwrap()
          .then(() => {
            notifyInfo('Mass DM sent successfully!');
            closeModal();
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Cancel',
      () => {
        closeModal();
      },
    );
  };

  return (
    <FlexMain>
      {selectedInfluencer.massDM.jobId ? (
        <Container>
          <Text20Bold>Next Mass DM: {new Date(selectedInfluencer.massDM.timestamp).toLocaleString()} </Text20Bold>
          <StyledButton title="Cancel Mass DM" onClick={onCancelMassDM} />
        </Container>
      ) : (
        <Container>
          <Select
            label="Album Media Type"
            selectedOption={albumMediaType}
            handleChange={setAlbumMediaType}
            options={Object.values(ALBUM_MEDIA_TYPE).map(type => ({
              value: type,
              label: ALBUM_MEDIA_TYPE_TITLE[type],
            }))}
          />
          <StyledButton title="Send Mass DM" onClick={onSendMassDM} />
        </Container>
      )}
    </FlexMain>
  );
};

export default InfluencerMassDmTab;
