import React, { memo } from 'react';
import {
  CaptionText,
  Container,
  DeleteIcon,
  EditIcon,
  PrimaryActionContainer,
  SecondaryActionContainer,
  StarContainer,
  TextContainer,
} from './AlbumMedia.styles';
import Image from '../utils/Image/Image';
import Video from '../utils/Video/Video';
import { MEDIA_TYPE, VALUE_OF_ONE_STAR_IN_USD } from '../../utils/constants';
import { useDrag, useDrop } from 'react-dnd';

const AlbumMedia = ({
  item,
  onClick,
  onClickParams,
  src,
  onDelete,
  onEdit,
  mediaType,
  index,
  alt,
  className,
  movePhoto,
  disableDragAndDrop,
  sectionKey,
  onDragging,
  hideCost = false,
}) => {
  // onClick params
  const onClickAllParams = [];
  item && onClickAllParams.push(item);
  onClickParams && onClickAllParams.push(...Object.values(onClickParams));

  const [, ref] = useDrag({
    type: sectionKey,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: sectionKey,
    drop: draggedItem => {
      movePhoto(draggedItem.index, index);
      draggedItem.index = index;
    },
    hover: draggedItem => {
      if (draggedItem.index !== index) {
        onDragging(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });
  return (
    <Container
      ref={node => {
        if (!disableDragAndDrop) {
          ref(drop(node));
        }
      }}
      className={className}>
      {mediaType === MEDIA_TYPE.VIDEO ? (
        <Video src={src} onClick={() => onClick(...onClickAllParams)} />
      ) : (
        <Image src={src} alt={alt} onClick={() => onClick(...onClickAllParams)} />
      )}
      {!!onDelete && (
        <PrimaryActionContainer onClick={onDelete}>
          <DeleteIcon />
        </PrimaryActionContainer>
      )}
      {!!onEdit && (
        <SecondaryActionContainer onClick={onEdit}>
          <EditIcon />
        </SecondaryActionContainer>
      )}
      {!hideCost && (
        <StarContainer>{`${item.cost} ⭐️ = $${(item.cost * VALUE_OF_ONE_STAR_IN_USD).toFixed(1)} `}</StarContainer>
      )}
      <TextContainer>
        <CaptionText>{item.caption}</CaptionText>
      </TextContainer>
    </Container>
  );
};

export default memo(AlbumMedia);
