import React from 'react';
import {
  LastSectionItemContainer,
  LogoIcon,
  SectionItemContainer,
  SectionItemIconContainer,
  SectionItemText,
  SectionName,
  Shadow,
  Sidebar,
} from './PrivateNavigation.styles';
import { ReactComponent as UsersIcon } from '../../../assets/icons/users.svg';
import { ReactComponent as InfluencerIcon } from '../../../assets/icons/influencers.svg';
import { ReactComponent as KeyIcon } from '../../../assets/icons/key.svg';
import { ReactComponent as ReceiptIcon } from '../../../assets/icons/receipt.svg';
import { ReactComponent as AddPersonIcon } from '../../../assets/icons/add_person.svg';
import { ReactComponent as UserSquareIcon } from '../../../assets/icons/user-square.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout.svg';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/slices/auth/asyncThunks';

function PrivateNavigation() {
  const dispatch = useDispatch();

  return (
    <Sidebar>
      <Shadow />
      <LogoIcon />
      <SectionName $first>Manage</SectionName>
      <SectionItemContainer $first>
        <NavLink to="/influencers">
          <SectionItemIconContainer>
            <InfluencerIcon />
          </SectionItemIconContainer>
          <SectionItemText>Influencers</SectionItemText>
        </NavLink>
      </SectionItemContainer>
      <SectionItemContainer>
        <NavLink to="/influencer-requests">
          <SectionItemIconContainer>
            <AddPersonIcon fill="#fff" />
          </SectionItemIconContainer>
          <SectionItemText>Influencer Requests</SectionItemText>
        </NavLink>
      </SectionItemContainer>
      <SectionName>Manage</SectionName>
      <SectionItemContainer>
        <NavLink to="/users">
          <SectionItemIconContainer>
            <UsersIcon />
          </SectionItemIconContainer>
          <SectionItemText>Users</SectionItemText>
        </NavLink>
      </SectionItemContainer>
      <SectionItemContainer>
        <NavLink to="/invitation-codes">
          <SectionItemIconContainer>
            <KeyIcon fill="#fff" />
          </SectionItemIconContainer>
          <SectionItemText>Invitation Codes</SectionItemText>
        </NavLink>
      </SectionItemContainer>
      <SectionItemContainer>
        <NavLink to="/withdrawals">
          <SectionItemIconContainer>
            <ReceiptIcon />
          </SectionItemIconContainer>
          <SectionItemText>Withdrawals</SectionItemText>
        </NavLink>
      </SectionItemContainer>
      <SectionItemContainer>
        <NavLink to="/prompt-groups">
          <SectionItemIconContainer>
            <UserSquareIcon />
          </SectionItemIconContainer>
          <SectionItemText>Prompt Groups</SectionItemText>
        </NavLink>
      </SectionItemContainer>
      <LastSectionItemContainer onClick={() => dispatch(logout())}>
        <SectionItemIconContainer>
          <LogoutIcon />
        </SectionItemIconContainer>
        <SectionItemText>Logout</SectionItemText>
      </LastSectionItemContainer>
    </Sidebar>
  );
}

export default PrivateNavigation;
