import styled from 'styled-components';
import { Text15Regular, Text18Regular } from '../../../../../components/utils/texts/text.styles';

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 1rem 2rem 2rem 2rem;
  border: 1px solid #f2f0f7;
  border-radius: 1.2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 2rem;
`;

export const QuestionnaireContainer = styled.div`
  width: 40%;
  background-color: #fff;
  padding: 1rem 2rem 2rem 2rem;
  border: 1px solid #f2f0f7;
  border-radius: 1.2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: 1 / -1;
`;

export const ContainerFooter = styled.div`
  display: flex;
  grid-column: 1 / -1;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  margin-bottom: 2rem;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => (props.$first ? '2rem' : '4.4rem')};
  margin-bottom: 4.4rem;
`;

export const LeftDivider = styled.div`
  flex: 1;
  height: 1.2px;
  background: linear-gradient(90deg, #fff 0%, rgba(254, 145, 50, 0) 0.01%, rgba(73, 67, 93, 0.4) 100%);
  opacity: 0.8;
  margin-right: 2rem;
`;

export const RightDivider = styled.div`
  flex: 1;
  height: 1.2px;
  background: linear-gradient(270deg, #fff 0%, rgba(254, 145, 50, 0) 0.01%, rgba(73, 67, 93, 0.4) 100%);
  opacity: 0.8;
  margin-left: 2rem;
`;

export const QuestionnaireSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const AnswerText = styled(Text15Regular)`
  opacity: 0.5;
`;

export const QuestionnaireTitle = styled(Text18Regular)`
  text-decoration: underline;
`;
