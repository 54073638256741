import { createWithdrawal, fetchWithdrawals } from './asyncThunks';

export const fetchWithdrawalsBuilder = builder => {
  builder.addCase(fetchWithdrawals.pending, state => {
    state.fetchWithdrawalsInfo.pending = true;
  });
  builder.addCase(fetchWithdrawals.fulfilled, (state, { payload }) => {
    const { data, total } = payload;
    state.fetchWithdrawalsInfo.pending = false;
    state.fetchWithdrawalsInfo.data = data;
    state.fetchWithdrawalsInfo.total = total;
  });
  builder.addCase(fetchWithdrawals.rejected, state => {
    state.fetchWithdrawalsInfo.pending = false;
  });
};

export const createWithdrawalBuilder = builder => {
  builder.addCase(createWithdrawal.pending, state => {
    state.pending.createWithdrawal = true;
  });
  builder.addCase(createWithdrawal.fulfilled, state => {
    state.pending.createWithdrawal = false;
  });
  builder.addCase(createWithdrawal.rejected, state => {
    state.pending.createWithdrawal = false;
  });
};
