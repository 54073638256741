import styled from 'styled-components';
import { ReactComponent as VoiceSvg } from '../../../../assets/icons/voice.svg';

export const Container = styled.div``;

export const UploadFileInputContainer = styled.div`
  height: ${props => (props.$increasedFileHeight ? '30rem' : '18rem')};
  width: ${props => (props.$increasedFileWidth ? 'auto' : props.$increasedFileHeight ? '30rem' : '18rem')};
  flex: ${props => (props.$increasedFileWidth ? '1' : 'none')};
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(73, 67, 93, 0.2);
  border-radius: 12px;
  overflow: hidden;
`;

export const UploadFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;

  &:focus {
    outline: none;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const UploadFileHelperContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
`;

export const UplaodFileText = styled.span`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.7rem;
`;

export const AllowedFileExtensionsText = styled.span`
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.3rem;
  text-align: center;
  margin-inline: 1.2rem;
`;

export const VoiceIcon = styled(VoiceSvg)`
  margin: auto;
`;
