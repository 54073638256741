import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import { fetchInfluencerRequestByIdBuilder, fetchInfluencerRequestsBuilder } from './builder';

const INIT_STATE = {
  fetchInfluencerRequestsInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  pending: {
    fetchInfluencerRequestById: false,
  },
};

export const influencerRequestSlice = createSlice({
  name: 'influencerRequest',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    fetchInfluencerRequestsBuilder(builder);
    fetchInfluencerRequestByIdBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const selectFetchInfluencerRequestsInfo = state => state.influencerRequestInfo.fetchInfluencerRequestsInfo;
export const selectFetchInfluencerRequestByIdPending = state =>
  state.influencerRequestInfo.pending.fetchInfluencerRequestById;

export default influencerRequestSlice.reducer;
