import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/a/influencer-requests';

class InfluencerRequestService {
  fetchInfluencerRequests = async (page, search) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencer requests.');
    }
  };

  downloadZippedMediaFiles = async influencerRequestId => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/media-files/${influencerRequestId}`, {
        responseType: 'blob',
      });
      throwErrorIfWrongStatus(response, 200);
      return response.data;
    } catch (err) {
      throw handleError(err, 'Error occurred while downloading zipped media files.');
    }
  };

  fetchInfluencerRequestById = async ({ influencerRequestId }) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${influencerRequestId}`);
      throwErrorIfWrongStatus(response, 200);
      const { influencerRequest } = response.data.data;
      return { influencerRequest };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencer request.');
    }
  };
}

const influencerRequestService = new InfluencerRequestService();
export default influencerRequestService;
