import { createInvitationCode, deleteInvitationCode, fetchInvitationCodes } from './asyncThunks';

export const fetchInvitationCodesBuilder = builder => {
  builder.addCase(fetchInvitationCodes.pending, state => {
    state.fetchInvitationCodesInfo.pending = true;
  });
  builder.addCase(fetchInvitationCodes.fulfilled, (state, { payload }) => {
    const { data, total } = payload;
    state.fetchInvitationCodesInfo.pending = false;
    state.fetchInvitationCodesInfo.data = data;
    state.fetchInvitationCodesInfo.total = total;
  });
  builder.addCase(fetchInvitationCodes.rejected, state => {
    state.fetchInvitationCodesInfo.pending = false;
  });
};

export const createInvitationCodeBuilder = builder => {
  builder.addCase(createInvitationCode.pending, state => {
    state.pending.createInvitationCode = true;
  });
  builder.addCase(createInvitationCode.fulfilled, state => {
    state.pending.createInvitationCode = false;
  });
  builder.addCase(createInvitationCode.rejected, state => {
    state.pending.createInvitationCode = false;
  });
};

export const deleteInvitationCodeBuilder = builder => {
  builder.addCase(deleteInvitationCode.pending, state => {
    state.pending.deleteInvitationCode = true;
  });
  builder.addCase(deleteInvitationCode.fulfilled, state => {
    state.pending.deleteInvitationCode = false;
  });
  builder.addCase(deleteInvitationCode.rejected, state => {
    state.pending.deleteInvitationCode = false;
  });
};
