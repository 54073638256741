import { capitalizeFirstLetter } from './util';

export const BASE_URL = 'https://staging-api.onlychats.xyz';
// export const BASE_URL = 'https://hercules.onlychats.xyz';
// export const BASE_URL = 'http://localhost:5001';

// Upload
export const UPLOAD_FILE_CONSTRAINTS = {
  TYPE: {
    PHOTO: 'PHOTO',
    VIDEO: 'VIDEO',
    AUDIO: 'AUDIO',
  },
  PHOTO: {
    MAX_SIZE_IN_BYTES: 10 * 1024 * 1024, // 10 MB
    ALLOWED_EXTENSIONS: ['png', 'jpeg', 'jpg'],
    ALLOWED_EXTENSIONS_TEXT: '.png, .jpeg, .jpg',
  },
  AUDIO: {
    MAX_SIZE_IN_BYTES: 200 * 1024 * 1024, // 200 MB
    ALLOWED_EXTENSIONS: ['mpeg3', 'x-mpeg-3', 'mpeg', 'm4a', 'x-m4a', 'wav', 'x-wav'],
    ALLOWED_EXTENSIONS_TEXT: '.mp3, .wav, .m4a',
  },
  VIDEO: {
    MAX_SIZE_IN_BYTES: 200 * 1024 * 1024, // 200 MB
    ALLOWED_EXTENSIONS: ['mp4', 'x-mp4', 'quicktime'],
    ALLOWED_EXTENSIONS_TEXT: '.mp4, .mov, .MOV',
  },
};

export const ENVIRONMENT = {
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
};

// Store
export const LOGOUT = 'LOGOUT';

// Roles
export const INFLUENCER_ROLE = {
  INFLUENCER: 'INFLUENCER',
};
export const INFLUENCER_ROLES = [INFLUENCER_ROLE.INFLUENCER];

export const ADMIN_ROLE = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};
export const ADMIN_ROLES = [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN];

export const MODEL = {
  BASIC: 'BASIC',
  ULTRANSFW: 'ULTRANSFW',
};
export const MODELS = [MODEL.BASIC, MODEL.ULTRANSFW];
export const MODELS_FOR_FORM = MODELS.map(model => ({
  value: model,
  label: capitalizeFirstLetter(model),
}));

export const MEDIA_TYPE = {
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
};

export const MEDIA_CATEGORY = {
  CHANNEL: 'CHANNEL',
  PREMIUM: 'PREMIUM',
};

export const INFLUENCER_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const INFLUENCER_CATEGORY = {
  PERSON: 'PERSON',
  ANIME: 'ANIME',
};
export const INFLUENCER_CATEGORIES = Object.values(INFLUENCER_CATEGORY);

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};
export const GENDERS = Object.values(GENDER);

export const VOICE_PROVIDER = {
  CARTESIA: 'CARTESIA',
  ELEVEN_LABS: 'ELEVEN_LABS',
};

export const VOICE_PROVIDER_TITLE = {
  CARTESIA: 'Cartesia',
  ELEVEN_LABS: 'Eleven Labs',
};

export const VALUE_OF_ONE_STAR_IN_USD = 0.02;
export const STARS_FOR_ONE_USD = 1 / VALUE_OF_ONE_STAR_IN_USD;

export const ALBUM_MEDIA_TYPE = {
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
  BUNDLE: 'BUNDLE',
  RANDOM: 'RANDOM',
};
