import React, { useEffect, useRef, useState } from 'react';
import { FlexHeader, FlexMain } from '../../../../components/utils/utils.styles';
import { AddNewMediaBundleButton, MediaBundlesContainer, Title } from './InfluencerMediaBundlesTab.styles';
import CreateMediaBundleFormModal from '../../../../components/modals/CreateMediaBundleFormModal/CreateMediaBundleFormModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeMediaBundlePosition,
  fetchInfluencerMediaBundles,
} from '../../../../store/slices/influencer/asyncThunks';
import {
  selectFetchInfluencerMediaBundlesInfo,
  setInfluencerMediaBundles,
} from '../../../../store/slices/influencer/slice';
import MainLoader from '../../../../components/utils/loaders/MainLoader/MainLoader';
import MediaBundle from '../../../../components/MediaBundle/MediaBundle';
import { notifyError } from '../../../../utils/notify';

const InfluencerMediaBundlesTab = () => {
  const { data, pending } = useSelector(selectFetchInfluencerMediaBundlesInfo);
  const [selectedMediaBundle, setSelectedMediaBundle] = useState(null);
  const mediaBundleFormModalRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInfluencerMediaBundles());
  }, [dispatch]);

  const onBundleClick = mediaBundleId => {
    setSelectedMediaBundle(mediaBundleId);
    mediaBundleFormModalRef.current.show();
  };

  const moveMediaBundle = (fromIndex, toIndex) => {
    const oldData = [...data];
    const updatedMedia = [...data];
    const [movedMedia] = updatedMedia.splice(fromIndex, 1);
    updatedMedia.splice(toIndex, 0, movedMedia);
    dispatch(setInfluencerMediaBundles(updatedMedia));

    const mediaPositions = updatedMedia.map((media, index) => ({ id: media.id, position: index + 1 }));
    dispatch(changeMediaBundlePosition({ mediaPositions }))
      .unwrap()
      .then(() => {})
      .catch(err => {
        dispatch(setInfluencerMediaBundles(oldData));
        notifyError(err.message);
      });
  };

  const onDragging = (fromIndex, toIndex) => {
    const updatedMedia = [...data];
    const [movedMedia] = updatedMedia.splice(fromIndex, 1);
    updatedMedia.splice(toIndex, 0, movedMedia);
    dispatch(setInfluencerMediaBundles(updatedMedia));
  };

  if (pending) {
    return <MainLoader />;
  }

  return (
    <>
      <FlexHeader>
        <Title>Bundles</Title>
        <AddNewMediaBundleButton
          title="Add Bundle"
          onClick={() => {
            mediaBundleFormModalRef.current.show();
          }}
        />
      </FlexHeader>
      <FlexMain>
        <MediaBundlesContainer>
          {data.map((mediaBundle, index) => {
            return (
              <MediaBundle
                index={index}
                key={mediaBundle.id}
                mediaBundle={mediaBundle}
                onClick={() => onBundleClick(mediaBundle.id)}
                moveMediaBundle={moveMediaBundle}
                onDragging={onDragging}
              />
            );
          })}
        </MediaBundlesContainer>
      </FlexMain>
      <CreateMediaBundleFormModal
        selectedMediaBundle={selectedMediaBundle}
        setSelectedMediaBundle={setSelectedMediaBundle}
        modalRef={mediaBundleFormModalRef}
        onSuccess={() => {
          dispatch(fetchInfluencerMediaBundles());
        }}
      />
    </>
  );
};

export default InfluencerMediaBundlesTab;
