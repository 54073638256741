import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCreateWithdrawalPending } from '../../../store/slices/withdrawal/slice';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { createWithdrawal, fetchAvailableAmountsForWithdrawal } from '../../../store/slices/withdrawal/asyncThunks';
import { FormNumericInput, FormSelect } from '../../../forms/form.styles';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { AvailableAmountContainer } from './CreateWithdrawalFormModal.styles';
import { Text16Bold } from '../../utils/texts/text.styles';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';

const CreateWithdrawalFormModal = ({ modalRef, onSuccess }) => {
  const createWithdrawalPending = useSelector(selectCreateWithdrawalPending);
  const dispatch = useDispatch();
  const [availableForWithdrawal, setAvailableForWithdrawal] = useState([]);
  const [maxAmountForWithdrawal, setMaxAmountForWithdrawal] = useState(0);

  const fetchAvailableAmountsForWithdrawalData = useCallback(() => {
    dispatch(fetchAvailableAmountsForWithdrawal())
      .unwrap()
      .then(({ data }) => {
        const formattedData = data.map(item => ({
          label: item.influencer.name,
          value: { id: item.influencer.id, availableAmount: item.earnings.available },
        }));

        setAvailableForWithdrawal(formattedData);
      })
      .catch(err => {
        notifyError(err.message);
      });
  }, [dispatch]);

  useEffect(() => {
    fetchAvailableAmountsForWithdrawalData();
  }, [fetchAvailableAmountsForWithdrawalData]);

  const createWithdrawalFormSchema = useMemo(
    () =>
      yup
        .object({
          influencer: yup.mixed().required('Please select a influencer'),
          amount: yup
            .number()
            .typeError('Amount must be a number')
            .required('Amount is required')
            .min(0.001, 'Amount must be above 0')
            .max(maxAmountForWithdrawal, 'Amount must be less than available amount'),
        })
        .required(),
    [maxAmountForWithdrawal],
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      influencer: null,
      amount: '',
    },
    delayError: 300,
    resolver: yupResolver(createWithdrawalFormSchema),
  });

  const influencerWatch = watch('influencer');

  useEffect(() => {
    if (influencerWatch) {
      setMaxAmountForWithdrawal(influencerWatch.value.availableAmount);
    }
  }, [influencerWatch]);

  const onSubmit = data => {
    const { influencer, amount } = data;
    dispatch(createWithdrawal({ influencerId: influencer.value.id, amount }))
      .unwrap()
      .then(_ => {
        fetchAvailableAmountsForWithdrawalData();
        reset();
        modalRef.current.hide();
        notifyInfo('Withdrawal created successfully!');
        onSuccess();
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <FormModal
      ref={modalRef}
      title="Create Withdrawal"
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
        setMaxAmountForWithdrawal(0);
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText="Submit"
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={createWithdrawalPending}
      shouldCloseOnOverlayClick={false}>
      <form>
        <Controller
          name="influencer"
          control={control}
          render={({ field }) => (
            <FormSelect
              placeholder="Select influencer"
              label="Influencer"
              options={availableForWithdrawal}
              selectedOption={field.value}
              handleChange={value => field.onChange(value)}
              error={errors.influencer?.message}
            />
          )}
        />
        <AvailableAmountContainer>
          <Text16Bold>
            Available for withdrawal: ⭐️ {maxAmountForWithdrawal} = $
            {maxAmountForWithdrawal * VALUE_OF_ONE_STAR_IN_USD}
          </Text16Bold>
        </AvailableAmountContainer>
        <FormNumericInput
          label="Amount (Stars)"
          {...register('amount', {
            valueAsNumber: true,
          })}
          placeholder="Enter amount"
          error={errors.amount?.message}
        />
      </form>
    </FormModal>
  );
};

export default CreateWithdrawalFormModal;
