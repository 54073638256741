import styled from 'styled-components';

export const StyledLabel = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: ${props => (props.disabled ? 0.8 : 1)};

  .datePicker {
    font-size: 1.5rem;
    line-height: normal;
    font-weight: 300;
    color: #49435d;
    height: 4.8rem;
    width: 100%;
    border-radius: 12px;
    padding: 0.8rem 1.2rem;
    box-shadow: none;
    outline: none;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
  }

  .react-datepicker {
    font-family: Lexend;
    overflow: hidden;
    display: flex;
    border-radius: 16px;
    border: none;
    padding: 2rem 2.4rem;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .react-datepicker__input-container {
    height: 100%;

    input {
      height: 100%;
      width: 100%;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 300;
      color: #49435d;
      border: none;
      outline: none;
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
    padding: unset;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: #000;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.4rem;
    margin-bottom: 1.2rem;
  }

  .react-datepicker__day-names {
    opacity: 0.5;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #000;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    width: 4rem;
    height: 4rem;
    padding: 1rem 0;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: #9e74f9;
    color: #000;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    width: 4rem;
    height: 4rem;
    padding: 1rem 0;
  }

  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    color: #000;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    width: 4rem;
    height: 4rem;
    padding: 1rem 0;

    &:hover {
      border-radius: 50%;
      background-color: #9e74f9;
      opacity: 0.5;
    }
  }

  .react-datepicker__day--outside-month {
    opacity: 0.3;
  }

  .react-datepicker__navigation {
    top: unset;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    outline: none;

    &:hover {
      opacity: 1;
    }
  }

  .react-datepicker__navigation--previous {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 3.4rem;
    padding: 0.8rem;

    span {
      left: 0;
      right: 0;
    }
  }
  .react-datepicker__navigation-icon::before {
    border-color: #996ff3;
    border-width: 1.5px 1.5px 0 0;
    height: 1rem;
    width: 1rem;
    top: 4px;
  }

  .react-datepicker__navigation--next {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 4rem;
    padding: 0.8rem;

    span {
      left: 0;
      right: 0;
    }
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    opacity: 0.3;
  }

  .react-multiple-carousel__arrow--left {
    display: none;
  }
`;

export const StyledLabelRow = styled.div`
  display: flex;
  align-items: center;
  margin-inline: 0.2rem;
  margin-bottom: 0.6rem;
`;
