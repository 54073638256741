import React from 'react';
import { Fragment } from 'react';
import { ActionTableBodyItem, TableBodyItem } from '../../Table.styles';
import { EditIcon } from './PromptGroupItem.styles';

const PromptGroupItem = ({ name, onEdit }) => {
  return (
    <Fragment>
      <TableBodyItem>
        <p>{name || '-'}</p>
      </TableBodyItem>
      <ActionTableBodyItem>
        <EditIcon onClick={onEdit} />
      </ActionTableBodyItem>
    </Fragment>
  );
};

export default PromptGroupItem;
