import React, { useEffect, useMemo, useRef } from 'react';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormInput, FormNumericInput } from '../../../forms/form.styles';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { Text16Regular } from '../../utils/texts/text.styles';
import {
  DeleteIconWrapper,
  MediaCardContainer,
  PriceInUsd,
  Row,
  SelectedMediaContainer,
  SelectMedia,
  SelectMediaContainer,
  StarContainer,
} from './CreateMediaBundleFormModal.styles';
import ErrorContainer from '../../utils/ErrorContainer/ErrorContainer';
import SelectMediaModal from '../SelectMediaModal/SelectMediaModal';
import { createMediaBundleFormSchema } from '../../../forms/schemas/albumMedia.schema';
import Video from '../../utils/Video/Video';
import Image from '../../utils/Image/Image';
import { MEDIA_TYPE, VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import {
  selectCreateMediaBundlePending,
  selectEditMediaBundlePending,
  selectFetchMediaBundleByIdPending,
} from '../../../store/slices/influencer/slice';
import { createMediaBundle, editMediaBundle, fetchMediaBundleById } from '../../../store/slices/influencer/asyncThunks';
import MainLoader from '../../utils/loaders/MainLoader/MainLoader';

const CreateMediaBundleFormModal = ({ modalRef, onSuccess, selectedMediaBundle, setSelectedMediaBundle }) => {
  const selectMediaModalRef = useRef();
  const createMediaBundlePending = useSelector(selectCreateMediaBundlePending);
  const editMediaBundlePending = useSelector(selectEditMediaBundlePending);
  const fetchMediaBundleByIdPending = useSelector(selectFetchMediaBundleByIdPending);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      media: [],
      caption: '',
      aiDescription: '',
      sale: '',
      cost: '',
    },
    delayError: 300,
    resolver: yupResolver(createMediaBundleFormSchema),
  });

  const mediaWatch = watch('media');
  const saleWatch = watch('sale');

  useEffect(() => {
    if (selectedMediaBundle) {
      dispatch(fetchMediaBundleById({ mediaBundleId: selectedMediaBundle }))
        .unwrap()
        .then(bundle => {
          setValue('media', bundle.media);
          setValue('caption', bundle.caption);
          setValue('aiDescription', bundle.aiDescription);
          setValue('sale', bundle.sale);
          const mediaPrice = bundle.media.reduce((acc, media) => acc + media.cost, 0);
          setValue('cost', Math.ceil(mediaPrice * (1 - bundle.sale / 100)));
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  }, [selectedMediaBundle, dispatch, setValue]);

  const bundlePriceInStars = useMemo(() => {
    if (!isNaN(saleWatch) && mediaWatch.length) {
      const mediaPrice = mediaWatch.reduce((acc, media) => acc + media.cost, 0);
      if (saleWatch === 0) {
        return mediaPrice;
      } else {
        const cost = Math.ceil(mediaPrice * (1 - saleWatch / 100));
        return cost;
      }
    } else {
      return 0;
    }
  }, [saleWatch, mediaWatch]);

  const onSubmit = data => {
    if (selectedMediaBundle) {
      dispatch(
        editMediaBundle({
          caption: data.caption,
          aiDescription: data.aiDescription,
          mediaBundleId: selectedMediaBundle,
          sale: data.sale,
        }),
      )
        .unwrap()
        .then(() => {
          reset();
          modalRef.current.hide();
          notifyInfo('Bundle updated successfully!');
          onSuccess();
        })
        .catch(err => {
          notifyError(err.message);
        });
    } else {
      const mediaData = data.media.map(media => {
        return {
          mediaId: media.id,
          type: media.type,
        };
      });

      dispatch(
        createMediaBundle({
          sale: data.sale,
          caption: data.caption,
          aiDescription: data.aiDescription,
          media: mediaData,
        }),
      )
        .unwrap()
        .then(() => {
          reset();
          modalRef.current.hide();
          notifyInfo('Bundle created successfully!');
          onSuccess();
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  };

  const onSelectMediaClick = () => {
    selectMediaModalRef.current.show();
  };

  const onDeleteMedia = mediaId => {
    let data = [...mediaWatch];
    data = data.filter(media => media.id !== mediaId);
    setValue('media', data);
  };

  return (
    <FormModal
      ref={modalRef}
      title={selectedMediaBundle ? 'Edit Bundle' : 'Create Bundle'}
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
        setSelectedMediaBundle(null);
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText={selectedMediaBundle ? 'Save' : 'Create'}
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={createMediaBundlePending || editMediaBundlePending}
      shouldCloseOnOverlayClick={false}>
      {fetchMediaBundleByIdPending ? (
        <MainLoader />
      ) : (
        <form>
          <SelectMediaContainer>
            <Text16Regular>Media</Text16Regular>
            {!selectedMediaBundle && <SelectMedia onClick={onSelectMediaClick}>Select Media</SelectMedia>}
          </SelectMediaContainer>
          <SelectedMediaContainer>
            {mediaWatch.map(item => {
              return (
                <MediaCard
                  key={item.id}
                  item={item}
                  type={item.type}
                  {...(!selectedMediaBundle && { onDeleteClick: () => onDeleteMedia(item.id) })}
                />
              );
            })}
          </SelectedMediaContainer>
          <ErrorContainer errorText={errors.media?.message} />
          <FormInput
            label="AI description"
            placeholder="Enter AI description"
            {...register('aiDescription')}
            error={errors.aiDescription?.message}
          />
          <FormInput
            label="Caption"
            placeholder="Enter caption"
            {...register('caption')}
            error={errors.caption?.message}
          />
          <Row>
            <FormNumericInput
              label="Sale (%)"
              {...register('sale', {
                valueAsNumber: true,
                onChange: e => {
                  const value = e.target.value;
                  if (value) {
                    const mediaPrice = mediaWatch.reduce((acc, media) => acc + media.cost, 0);
                    const cost = Math.ceil(mediaPrice * (1 - value / 100));
                    setValue('cost', cost);
                  } else {
                    setValue('cost', '');
                  }
                  setValue('sale', value);
                },
              })}
              error={errors.sale?.message}
            />
            <FormNumericInput
              label="Cost (⭐️)"
              {...register('cost', {
                valueAsNumber: true,
                onChange: e => {
                  const value = e.target.value;
                  if (value) {
                    const mediaPrice = mediaWatch.reduce((acc, media) => acc + media.cost, 0);
                    const sale = 100 - (100 * value) / mediaPrice;
                    setValue('sale', sale);
                  } else {
                    setValue('sale', '');
                  }
                  setValue('cost', value);
                },
              })}
              error={errors.cost?.message}
            />
            <PriceInUsd>{`= $${(bundlePriceInStars * VALUE_OF_ONE_STAR_IN_USD).toFixed(2)}`}</PriceInUsd>
          </Row>
        </form>
      )}
      <SelectMediaModal
        modalRef={selectMediaModalRef}
        onSelect={media => setValue('media', [...mediaWatch, ...media], { shouldValidate: true })}
        chosenMedia={mediaWatch}
      />
    </FormModal>
  );
};

const MediaCard = ({ item, type, onDeleteClick }) => {
  return (
    <MediaCardContainer>
      {onDeleteClick && (
        <DeleteIconWrapper onClick={onDeleteClick}>
          <DeleteIcon width={20} height={20} fill="white" />
        </DeleteIconWrapper>
      )}
      {type === MEDIA_TYPE.PHOTO ? (
        <Image src={item.url} alt="selected-premium-photo-media" />
      ) : (
        <Video src={item.url} />
      )}
      <StarContainer>{`${item.cost} ⭐️ = $${(item.cost * VALUE_OF_ONE_STAR_IN_USD).toFixed(1)} `}</StarContainer>
    </MediaCardContainer>
  );
};

export default CreateMediaBundleFormModal;
