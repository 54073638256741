import { fetchInfluencerRequestById, fetchInfluencerRequests } from './asyncThunks';

export const fetchInfluencerRequestsBuilder = builder => {
  builder.addCase(fetchInfluencerRequests.pending, state => {
    state.fetchInfluencerRequestsInfo.pending = true;
  });
  builder.addCase(fetchInfluencerRequests.fulfilled, (state, { payload }) => {
    const { data, total } = payload;
    state.fetchInfluencerRequestsInfo.pending = false;
    state.fetchInfluencerRequestsInfo.data = data;
    state.fetchInfluencerRequestsInfo.total = total;
  });
  builder.addCase(fetchInfluencerRequests.rejected, state => {
    state.fetchInfluencerRequestsInfo.pending = false;
  });
};

export const fetchInfluencerRequestByIdBuilder = builder => {
  builder.addCase(fetchInfluencerRequestById.pending, state => {
    state.pending.fetchInfluencerRequestById = true;
  });
  builder.addCase(fetchInfluencerRequestById.fulfilled, state => {
    state.pending.fetchInfluencerRequestById = false;
  });
  builder.addCase(fetchInfluencerRequestById.rejected, state => {
    state.pending.fetchInfluencerRequestById = false;
  });
};
